import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import {
  faWhatsapp,
  faAndroid,
  faApple
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FooterPagePro = () => {
  return (
    <MDBFooter
      color="unique-color-dark"
      className="page-footer font-small pt-0"
    >
      <div style={{ backgroundColor: "#3B82F6" }}>
        <MDBContainer fluid className="text-center text-md-left">
          <MDBRow className="py-4 d-flex align-items-center">
            <MDBCol
              md="6"
              lg="5"
              style={{ textAlign: "left" }}
              className="footer-social-media"
            >
              <h5
                className="mb-0 white-text"
                style={{ color: "white", paddingLeft: 10 }}
              >
                Get connected with us on social networks!
              </h5>
            </MDBCol>
            <MDBCol md="6" lg="7" style={{ fontSize: 18 }}>
              <a
                href="https://www.facebook.com/almaredagencyuganda"
                target="_blank"
                className="fb-ic ml-0"
                style={{ color: "white", cursor: "pointer" }}
              >
                <i className="fab fa-facebook-f white-text mr-lg-4"> </i>
              </a>
              <a
                className="tw-ic"
                style={{ color: "white", paddingLeft: 20, cursor: "pointer" }}
              >
                <i className="fab fa-twitter white-text mr-lg-4"> </i>
              </a>
              <a
                className="gplus-ic"
                style={{ color: "white", paddingLeft: 20, cursor: "pointer" }}
              >
                <i className="fab fa-google-plus-g white-text mr-lg-4"> </i>
              </a>
              <a
                className="li-ic"
                style={{ color: "white", paddingLeft: 20, cursor: "pointer" }}
              >
                <i className="fab fa-linkedin-in white-text mr-lg-4"> </i>
              </a>
              <a
                href="https://www.instagram.com/almaredagencyuganda/"
                target="_blank"
                className="ins-ic"
                style={{ color: "white", paddingLeft: 20, cursor: "pointer" }}
              >
                <i className="fab fa-instagram white-text mr-lg-4"> </i>
              </a>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <div
        style={{
          color: "white",
          backgroundColor: "#3B82F6",
          border:"1px solid #3B82F6"
        }}
      >
        <MDBContainer className="mt-5 mb-4 text-center text-md-left">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mb-4">
              <h6 className="text-uppercase font-weight-bold">
                <span>Almared Uganda Agency</span>
              </h6>
              <hr
                className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: "60px" }}
              />
              <p>
                {/* Almared Agency is a team of recruitment professionals matching people successfully with different employers globally. */}
                <b>ChequeHandler</b> is a platform that allows thousands of workers overseas to send salary, track it in a mobile wallet app and withdraw it in local currency.
              </p>
            </MDBCol>
            <MDBCol md="1" lg="1" xl="1" className="mb-4"></MDBCol>
            <MDBCol md="2" lg="2" xl="2" className="mb-4">
              <h6 className="text-uppercase font-weight-bold">
                <span className="alignLeft">Mobile Apps</span>
              </h6>
              <hr style={{ width: "60px" }} />
              <p className="alignTextLeft">
                <a href="#!" style={{ color: "white", textDecoration: "none" }}>
                  <span className="mr-3" style={{ fontSize: 19 }}>
                    <FontAwesomeIcon icon={faAndroid} color="white" />
                  </span>
                  Android App
                </a>
              </p>
              <p className="alignTextLeft">
                <a href="#!" style={{ color: "white", textDecoration: "none" }}>
                  <span className="mr-3" style={{ fontSize: 19 }}>
                    <FontAwesomeIcon icon={faApple} color="white" />
                  </span>
                  iOS App
                </a>
              </p>
              {/* <p className="alignTextLeft">
              <a href="#!" style={{color:"white",textDecoration:"none"}}>Backend</a>
            </p> */}
              {/* <p className="alignTextLeft">
              <a href="#!" style={{color:"white",textDecoration:"none"}}>Admin</a>
            </p> */}
            </MDBCol>
            <MDBCol md="3" lg="2" xl="2" className="mb-4">
              <h6 className="text-uppercase font-weight-bold">
                <span className="alignLeft">Useful links</span>
              </h6>
              <hr style={{ width: "60px" }} />
              <p className="alignTextLeft">
                <a
                  href="#!"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Register Business
                </a>
              </p>
              <p className="alignTextLeft">
                <a
                  href="#!"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Register an Agency
                </a>
              </p>
              <p className="alignTextLeft">
                <a
                  href="#!"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Employee
                </a>
              </p>
              <p className="alignTextLeft">
                <a href="#!" style={{ color: "white", textDecoration: "none" }}>
                  Help
                </a>
              </p>
            </MDBCol>
            <MDBCol md="4" lg="3" xl="3" className="mb-4">
              <h6 className="text-uppercase font-weight-bold">
                <span className="alignLeft">Contact</span>
              </h6>
              <hr style={{ width: "60px" }} />
              <p className="alignTextLeft">
                <i className="fa fa-home mr-3" />
                Kampala, Uganda
              </p>
              <p className="alignTextLeft">
                <i className="fa fa-envelope mr-3" />
                info@chequehandler.com
              </p>
              <p className="alignTextLeft">
                <i className="fa fa-phone mr-3" />+ 256 752 172 061
              </p>
              <p className="alignTextLeft">
                {/* <i className="fa fa-phone mr-3" />+ 256 702 584 049 */}
                <span className="mr-3" style={{ fontSize: 19 }}>
                  <FontAwesomeIcon icon={faWhatsapp} color="white" />
                </span>
                + 256 752 172 061
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <div
        className="footer-copyright text-center py-3"
        style={{ backgroundColor: "#3B82F6", color: "white" }}
      >
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <a
            href="https://almaredagency.com"
            target="_blank"
            style={{ color: "white",textDecoration:"none" }}
          >
            {" "}
            Almared Uganda Agency Ltd.
          </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default FooterPagePro;
