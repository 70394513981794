import React from "react";
import FooterPagePro from "./footer/footer";

const WrongPath = () => {
  return (
    <div style={{ minHeight: 500 }}>
      <div
        className="bg-indigo-500"
        style={{
          height: 60,
          color: "white",
          alignItems: "center",
          display: "flex",
          paddingLeft: 20,
        }}
      >
        <span style={{ fontSize: 20 }}>Cheque-Handler</span>
      </div>
      <div
        style={{
          minHeight: 300,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: 15,
        }}
      >
        <h2>Invalid URL ! This page was not found</h2>
      </div>
      <FooterPagePro />
    </div>
  );
};

export default WrongPath;
