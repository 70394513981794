import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  addCommissionToStore,
  addInvoiceToStore,
  addPaymentToStore,
  commissionAdded,
  invoiceAdded,
  showPopup,
} from "../../actions";
import ApiCalls from "../../api/apiCalls";
import { toast } from "react-toastify";

function CreateInvoice({ history }) {
  const dispatch = useDispatch();
  const showCreateInvoicePopup = useSelector(
    ({ showCreateInvoicePopup }) => showCreateInvoicePopup
  );
  const authToken = useSelector(({ userData: { token } }) => token);
  const invoice = useSelector(({ newInvoice }) => newInvoice);
  const invoices_currency = useSelector(
    ({ records: { invoices_currency } }) => invoices_currency
  );
  let api = new ApiCalls();

  // form fields
  const [amount, setAmount] = useState("");
  // submit button
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const handleChangeAmount = (event) => {
    setAmount(event.target.value);
  };

  const handleClose = () => {
    dispatch(showPopup("create_invoice", false));
  };

  const saveInvoice = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmitButtonDisabled(true);
    toast.promise(
      api.createInvoice(
        {
          amount: amount,
          employerId: invoice.employer.id,
          employeeId: invoice.employee.id,
          agencyId: invoice.agency.id,
        },
        authToken
      ),
      {
        pending: "Please wait...",
        success: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.status === 200) {
              let responseBody = data.data;
              console.log(responseBody.message);
              dispatch(addInvoiceToStore(responseBody.data.invoice)); // add it to redux store
              dispatch(invoiceAdded());
              dispatch(addPaymentToStore(responseBody.data.payment));
              if (responseBody.data.commission) {
                dispatch(addCommissionToStore(responseBody.data.commission));
                dispatch(commissionAdded());
              }
              //console.log("createdInvoice", responseBody.data.invoice);
              handleClose();
              return `${responseBody.message}`;
            }
          },
        },
        error: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.response) {
              console.log("status code " + data.response.status);
              let { message } = data.response.data;
              console.log("errorMessage:", message);
              return `${message}`;
            } else {
              console.log("An error occurred, try again later");
              return "An error occurred, try again later";
            }
          },
        },
      }
    );
  };

  useEffect(() => {
    setAmount(invoice.employee.monthlySalary);
  }, [invoice]);

  return (
    <Modal
      show={showCreateInvoicePopup}
      onHide={handleClose}
      centered
      backdrop={true}
      size="xl"
    >
      <Modal.Header closeButton style={{ fontSize: 40 }}>
        <Modal.Title>Create Invoice</Modal.Title>
      </Modal.Header>
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full shadow-md rounded-xl bg-blueGray-100 border-0">
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form onSubmit={saveInvoice}>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Invoice Details
              </h6>

              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Employer Name
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={invoice.employer.name}
                      disabled
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Employer Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={invoice.employer.email}
                      disabled
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Employee
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={invoice.employee.name}
                      disabled
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Amount in {invoices_currency}
                    </label>
                    <input
                      required
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={handleChangeAmount}
                      value={amount}
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center flex items-center justify-center mt-4">
                <button
                  className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                  type="submit"
                  disabled={isSubmitButtonDisabled}
                >
                  <span>Send Invoice</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    </Modal>
  );
}

export default withRouter(CreateInvoice);
