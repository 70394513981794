import React, { useState, useEffect } from "react";
import { MDBContainer, MDBDataTable } from "mdbreact";

import { Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { createCommission, showPopup } from "../../actions";

const Label = ({ label }) => {
  return (
    <span style={{ cursor: "pointer" }}>
      {label}
      <span
        class="text-blueGray-500 "
        style={{
          marginLeft: 4,
        }}
      >
        ↑↓
      </span>
    </span>
  );
};

function Agencies({ history }) {
  const [agenciesDataTable, setAgenciesDataTable] = useState({});
  const agencies = useSelector(({ records: { agencies } }) => agencies);
  const showSelectAgencyPopup = useSelector(
    ({ showSelectAgencyPopup }) => showSelectAgencyPopup
  );
  const [scrollY, setScrollY] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const displayData = agencies.reverse().map((agency) => {
      return {
        name: agency.name,
        phoneNumber: agency.phoneNumber,
        address: agency.address,
        bankName: agency.bankName,
        bankAccountNumber: agency.bankAccountNumber,
        clickEvent: () => {
          //agency.id
          dispatch(
            createCommission({
              agency: {
                id: agency.id,
                name: agency.name,
                phoneNumber: agency.phoneNumber,
                bankName: agency.bankName,
              },
            })
          );
          dispatch(showPopup("create_commission", true));
          dispatch(showPopup("select_agency", false));
        },
      };
    });

    setAgenciesDataTable({
      columns: [
        {
          label: <Label label="Name" />,
          field: "name",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Phone" />,
          field: "phoneNumber",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Address" />,
          field: "address",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Bank" />,
          field: "bankName",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Account" />,
          field: "bankAccountNumber",
          sort: "asc",
          width: 100,
        },
      ],
      rows: displayData,
    });
  }, [agencies]);

  const handleClose = () => {
    dispatch(showPopup("select_agency", false));
  };

  useEffect(() => {
    if (window.innerWidth >= 775) {
      setScrollY(false);
    } else {
      setScrollY(true);
    }
    const handleResize = (event) => {
      var width = window.innerWidth;
      if (width >= 775) {
        setScrollY(false);
      } else {
        setScrollY(true);
      }
    };
    window.addEventListener("resize", handleResize);

    // cleanup this component // when component unmounts // run this callback
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // runs only once // empty dependency list

  return (
    <Modal
      show={showSelectAgencyPopup}
      onHide={handleClose}
      centered
      backdrop={false}
      size="xl"
    >
      <Modal.Header
        closeButton
        style={{
          backgroundColor: "#eee",
          fontSize: 40,
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}
      >
        <Modal.Title>Select Recruiting Agency</Modal.Title>
      </Modal.Header>
      <>
        <div className="bg-blueGray-100 padding-data-table py-2">
          <MDBContainer
            className="shadow-sm"
            style={{
              backgroundColor: "white",
              paddingTop: 10,
              borderRadius: 10,
              paddingBottom: 10,
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            <MDBDataTable
              borderless
              maxHeight="100vh"
              hover
              //scrollX
              //scrollY={scrollY}
              //striped
              //bordered
              //small
              responsive
              responsiveSm
              responsiveMd
              responsiveLg
              responsiveXl
              theadColor="#5072a7"
              data={agenciesDataTable}
              id="tableID"
              exportToCSV
              noBottomColumns
              pagesAmount={2}
            />
          </MDBContainer>
        </div>
      </>
    </Modal>
  );
}

export default withRouter(Agencies);
