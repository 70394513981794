import React, { useEffect, useRef, useState } from "react";

import { Image, Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  addemployerToStore,
  addOrModifyEmployeeToStore,
  employeeAddedOrUpdated,
  employerAdded,
  showPopup,
} from "../../actions";
import ApiCalls from "../../api/apiCalls";
import { toast } from "react-toastify";
import avatar from "../../images/avatar.jpg";
import logo from "../../images/cheque-handler.jpg";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function EditEmployee({ history }) {
  const dispatch = useDispatch();
  const showEditEmployeePopup = useSelector(
    ({ showEditEmployeePopup }) => showEditEmployeePopup
  );
  const authToken = useSelector(({ userData: { token } }) => token);
  const employees = useSelector(({ records: { employees } }) => employees);
  const employers = useSelector(({ records: { employers } }) => employers);
  const users = useSelector(({ records: { users } }) => users);
  const editEmployee = useSelector(({ editEmployee }) => editEmployee);
  const user_group_data = useSelector(
    (state) => state.userData.user_data.user_group_data
  );
  const bankslist = useSelector(({ bankslist }) => bankslist);
  const AddedOrUpdated = useSelector(
    ({ employeeAddedOrUpdated }) => employeeAddedOrUpdated
  );
  const invoices_currency = useSelector(
    ({ records: { invoices_currency } }) => invoices_currency
  );
  const [editType, setEditType] = useState("update");
  // employee form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [village, setVillage] = useState("");
  const [nin, setNIN] = useState(""); // NIN / Passport Number
  const [dob, setDOB] = useState(""); // date of birth
  const [bankName, setBankName] = useState(null);
  const [bankCode, setBankCode] = useState(null);
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [district, setDistrict] = useState("");
  const [parentGuardian, setParentGuardian] = useState("");
  const [lc1Name, setLc1Name] = useState("");
  const [bioData, setBioData] = useState("");
  const [gender, setGender] = useState("");
  const [salary, setSalary] = useState("");
  const [employer, setEmployer] = useState(null);
  const [agency, setAgency] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  // submit button
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [isSubmitButtonDisabled2, setIsSubmitButtonDisabled2] = useState(false);
  const [showAddEmployerModal, setShowAddEmployerModal] = useState(false);

  const [autoCompleteEmployer, setAutoCompleteEmployer] = useState({
    id: "",
    name: "",
  });
  const [autoCompleteBank, setAutoCompleteBank] = useState({
    code: "",
    name: "",
  });

  // create employer form fields
  const [newEmployerAddress, setNewEmployerAddress] = useState("");
  const [newEmployerCountry, setNewEmployerCountry] = useState("");
  const [newEmployerState, setNewEmployerState] = useState("");
  const [newEmployerName, setNewEmployerName] = useState("");
  const [newEmployerEmail, setNewEmployerEmail] = useState("");
  const [newEmployerPhoneNumber, setNewEmployerPhoneNumber] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const [imageStatus, setImageStatus] = useState("Add Image");

  const ref = useRef(null);

  let api = new ApiCalls();

  const handleChangeFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const handleChangeLastName = (event) => {
    setLastName(event.target.value);
  };
  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleChangeAddress = (event) => {
    setAddress(event.target.value);
  };
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleChangeState = (event) => {
    setState(event.target.value);
  };
  const handleChangeVillage = (event) => {
    setVillage(event.target.value);
  };
  const handleChangeNIN = (event) => {
    setNIN(event.target.value);
  };
  const handleChangeDOB = (event) => {
    setDOB(event.target.value);
  };
  const handleChangeBankCode = (event, bank) => {
    // if an object is selected
    if (bank) {
      setBankCode(bank.code);
      setBankName(bank.name);
      setAutoCompleteBank(bank);
    } else {
      // if an object is selected
      setBankCode(null);
      setBankName(null);
      setAutoCompleteBank({ code: "", name: "" });
    }
  };
  const handleChangeBankAccountNumber = (event) => {
    setBankAccountNumber(event.target.value);
  };
  const handleChangeDistrict = (event) => {
    setDistrict(event.target.value);
  };
  const handleChangeParentGuardian = (event) => {
    setParentGuardian(event.target.value);
  };
  const handleChangeLC1Name = (event) => {
    setLc1Name(event.target.value);
  };
  const handleChangeBioData = (event) => {
    setBioData(event.target.value);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };
  const handleChangeSartDate = (event) => {
    setStartDate(event.target.value);
  };
  const handleChangeEmployer = (event, employer) => {
    // if an object is selected
    if (employer) {
      setEmployer(employer.id);
      setAutoCompleteEmployer(employer);
    } else {
      // object is unselected
      setEmployer(null);
      setAutoCompleteEmployer({ id: "", name: "" });
      setIsActive(false); // if no employer, deactivate the employee
    }
  };
  const handleChangeIsActive = (event) => {
    if (employer != null) {
      setIsActive(!isActive);
    } else {
      // show toast // "You can't activate this employee because there is no employer selected"
      toast(
        "You can't activate this employee because there is no employer selected",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "warning",
        }
      );
    }
  };
  const handleChangeSalary = (event) => {
    setSalary(event.target.value);
  };

  // create employer form // fields onChange
  const handleChangeNewEmployerName = (event) => {
    setNewEmployerName(event.target.value);
  };
  const handleChangeNewEmployerEmail = (event) => {
    setNewEmployerEmail(event.target.value);
  };
  const handleChangeNewEmployerPhoneNumber = (event) => {
    setNewEmployerPhoneNumber(event.target.value);
  };
  const handleChangeNewEmployerCountry = (event) => {
    setNewEmployerCountry(event.target.value);
  };
  const handleChangeNewEmployerState = (event) => {
    setNewEmployerState(event.target.value);
  };
  const handleChangeNewEmployerAddress = (event) => {
    setNewEmployerAddress(event.target.value);
  };

  const handleClose = () => {
    dispatch(showPopup("edit_employee", false));
  };

  const handleClose2 = () => {
    setShowAddEmployerModal(false);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);

    let file = event.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
    if (file && ALLOWED_TYPES.includes(file.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(`${reader.result}`);
      };
      reader.readAsDataURL(file);
    } else {
      console.log("something Went Wrong!");
    }
  };

  const saveEmployee = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmitButtonDisabled(true);

    console.log(`${profilePicture}`);
    console.log(selectedFile);

    let formData = new FormData();
    formData.append(
      "employeeId",
      editEmployee.employeeId != null ? editEmployee.employeeId.toString() : ""
    );
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("phoneNumber", phoneNumber);
    formData.append("email", email);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("address", address);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("village", village);
    formData.append(
      "dateOfBirth",
      dob != "" ? new Date(dob).toISOString().split("T")[0] : ""
    ); // convert to YYYY-MM-DD format
    formData.append("district", district);
    formData.append("parentGuardian", parentGuardian);
    formData.append("lc1Name", lc1Name);
    formData.append("bioData", bioData);
    formData.append("nin", nin);
    formData.append("employer", employer != null ? employer.toString() : "");
    formData.append(
      "agency",
      user_group_data.type === "agency"
        ? user_group_data.id.toString()
        : agency != null
        ? agency.toString()
        : ""
    );
    formData.append("bankCode", bankCode);
    formData.append("bankName", bankName);
    formData.append("bankAccountNumber", bankAccountNumber);
    formData.append("monthlySalary", salary.toString());
    formData.append(
      "startDate",
      startDate != "" ? new Date(startDate).toISOString().split("T")[0] : ""
    ); // convert to YYYY-MM-DD format
    formData.append("gender", gender);
    formData.append("isActive", isActive ? "true" : "false");
    // the selected file
    if (selectedFile != null) {
      formData.append("profilePicture", selectedFile);
      // console.log(formData.get("profilePicture").name);
    }

    toast.promise(api.updateEmployee(formData, authToken), {
      pending: "Please wait...",
      success: {
        render({ data }) {
          setIsSubmitButtonDisabled(false);
          if (data.status === 200) {
            let responseBody = data.data;
            dispatch(addOrModifyEmployeeToStore(responseBody.data));
            dispatch(employeeAddedOrUpdated());
            handleClose();
            return `${responseBody.message}`;
          }
        },
      },
      error: {
        render({ data }) {
          setIsSubmitButtonDisabled(false);
          if (data.response) {
            console.log("status code " + data.response.status);
            let { message } = data.response.data;
            console.log("errorMessage:", message);
            return `${message}`;
          } else {
            console.log("An error occurred, try again later");
            return "An error occurred, try again later";
          }
        },
      },
    });
  };

  useEffect(() => {
    if (editEmployee.employeeId != null) {
      let employee = employees.find(
        (employee) => employee.id === editEmployee.employeeId
      );

      setEditType("update"); // update fields

      setFirstName(employee.first_name);
      setLastName(employee.last_name);
      setGender(employee.gender != null ? employee.gender : "");
      setEmployer(employee.employer);
      if (employee.employer != null) {
        let employerData = employers.find(
          (employer) => employer.id === employee.employer
        );
        setAutoCompleteEmployer(employerData);
      } else {
        setAutoCompleteEmployer({ id: "", name: "" });
      }

      setProfilePicture(
        employee.profilePicture != null
          ? `${api.SERVER}${employee.profilePicture}`
          : ""
      );
      setImageStatus(
        profilePicture != avatar ? "Change Image" : `${imageStatus}`
      );
      let user = users.find((user) => user.id === employee.user);
      if (user) {
        setUsername(user.username);
        setEmail(user.email);
      } else {
        setUsername("");
        setEmail("");
      }
      setStartDate(employee.startDate != null ? employee.startDate : "");
      setLc1Name(employee.lc1Name != null ? employee.lc1Name : "");
      setPhoneNumber(employee.phoneNumber != null ? employee.phoneNumber : "");
      setAddress(employee.address != null ? employee.address : "");
      setDistrict(employee.district != null ? employee.district : "");
      setCountry(employee.country != null ? employee.country : "");
      setState(employee.state != null ? employee.state : "");
      setVillage(employee.village != null ? employee.village : "");
      setIsActive(employee.isActive);
      setBankName(employee.bankName); // if bankName --> null // it's fine here
      setBankCode(employee.bankCode); // if bankCode --> null // it's fine here
      setBankAccountNumber(
        employee.bankAccountNumber != null ? employee.bankAccountNumber : ""
      );
      if (employee.bankCode != null) {
        let bankData = bankslist.find(
          (bank) => bank.code === employee.bankCode
        );
        setAutoCompleteBank(bankData);
      } else {
        setAutoCompleteBank({ code: "", name: "" });
      }
      //
      setSalary(employee.monthlySalary != null ? employee.monthlySalary : "");
      setNIN(employee.nin != null ? employee.nin : "");
      setDOB(employee.dateOfBirth != null ? employee.dateOfBirth : "");
      setParentGuardian(
        employee.parentGuardian != null ? employee.parentGuardian : ""
      );
      setBioData(employee.bioData != null ? employee.bioData : "");
      setAgency(employee.agency);
      // ...
    } else {
      setEditType("create"); // create new employee // empty fields

      setFirstName("");
      setLastName("");
      setGender("");
      setEmployer(null);
      setAutoCompleteEmployer({ id: "", name: "" });
      setProfilePicture("");
      setUsername("");
      setEmail("");
      setStartDate("");
      setLc1Name("");
      setPhoneNumber("");
      setAddress("");
      setDistrict("");
      setCountry("");
      setState("");
      setVillage("");
      setIsActive(false);
      setBankCode(null);
      setBankName(null);
      setBankAccountNumber("");
      setSalary("");
      setNIN("");
      setDOB("");
      setParentGuardian("");
      setBioData("");
      setAgency(null);
      // ...
    }

    // cleanup this component // when component unmounts // run this callback
    return () => {
      setUsername(""); // just to enable editting (disabled input)
      setStartDate(""); // just to enable editting (disabled input)
      setEmail("");
      setAutoCompleteBank({ code: "", name: "" });
      setAutoCompleteEmployer({ id: "", name: "" });
      setIsActive(false);
      setPassword("");
      setSalary("");
      setProfilePicture("");
      setLc1Name("");
      setPhoneNumber("");
      setAddress("");
      setDistrict("");
      setCountry("");
      setState("");
      setVillage("");
      setBankAccountNumber("");
      setNIN("");
      setDOB("");
      setParentGuardian("");
      setBioData("");
      setAgency(null);
    };
  }, [editEmployee, AddedOrUpdated, bankslist]);

  const createEmployer = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsSubmitButtonDisabled2(true);
    toast.promise(
      api.createEmployer(
        {
          name: newEmployerName,
          email: newEmployerEmail,
          phoneNumber: newEmployerPhoneNumber,
          country: newEmployerCountry,
          state: newEmployerState,
          address: newEmployerAddress,
        },
        authToken
      ),
      {
        pending: "Please wait...",
        success: {
          render({ data }) {
            setIsSubmitButtonDisabled2(false);
            if (data.status === 200) {
              let responseBody = data.data;
              console.log(responseBody.message);
              dispatch(addemployerToStore(responseBody.data)); // add it to redux store
              dispatch(employerAdded());
              // clear form fields
              setNewEmployerName("");
              setNewEmployerEmail("");
              setNewEmployerPhoneNumber("");
              setNewEmployerCountry("");
              setNewEmployerState("");
              setNewEmployerAddress("");
              handleClose2();
              return `${responseBody.message}`;
            }
          },
        },
        error: {
          render({ data }) {
            setIsSubmitButtonDisabled2(false);
            if (data.response) {
              console.log("status code " + data.response.status);
              let { message } = data.response.data;
              console.log("errorMessage:", message);
              return `${message}`;
            } else {
              console.log("An error occurred, try again later");
              return "An error occurred, try again later";
            }
          },
        },
      }
    );
  };

  return (
    <>
      <Modal
        show={showEditEmployeePopup}
        onHide={handleClose}
        centered
        backdrop={false}
        size="xl"
      >
        <form onSubmit={saveEmployee}>
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#eee",
              fontSize: 40,
              position: "sticky",
              top: 0,
              zIndex: 2,
            }}
          >
            {/* <Modal.Title>
              {editType === "update" ? "Update Employee" : "Create Employee"}
            </Modal.Title> */}
            <button
              className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center text-sm focus:outline-none transition hover:shadow-md shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
              disabled={isSubmitButtonDisabled}
              type="submit"
            >
              <span>
                {editType === "update" ? "Update Employee" : "Save Employee"}
              </span>
            </button>
          </Modal.Header>
          <div className="employee-details-container">
            <div className="mr-4 ml-4 lg:mr-20 lg:ml-20 xl:mr-30 lx:ml-30">
              <div className="relative flex flex-col min-w-0 break-words w-full rounded-xl bg-blueGray-200 border-0 pt-20">
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  {/* <form onSubmit={saveEmployee}> */}
                  <h6 className="text-blueGray-500 text-sm mt-3 mb-6 font-bold uppercase">
                    Employee Details
                  </h6>

                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeFirstName}
                          value={firstName}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeLastName}
                          value={lastName}
                        />
                      </div>
                    </div>

                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {"Phone Number (add country code)"}
                        </label>
                        <input
                          type="tel"
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangePhoneNumber}
                          value={phoneNumber}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email address
                        </label>
                        <input
                          type="email"
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeEmail}
                          value={email}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Username
                        </label>
                        <input
                          type="text"
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeUsername}
                          value={username}
                          disabled={editType === "update"}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Password
                        </label>
                        <input
                          type="text"
                          required={editType === "create"}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangePassword}
                          value={password}
                        />
                      </div>
                    </div>
                  </div>

                  <hr className="mt-6 border-b-1 border-blueGray-300" />

                  <h6 className="text-blueGray-500 text-sm mt-3 mb-6 font-bold uppercase">
                    Contact Information
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Kansanga Ggabba road, 248"
                          onChange={handleChangeAddress}
                          value={address}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          District
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeDistrict}
                          value={district}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Country
                        </label>
                        <input
                          type="text"
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeCountry}
                          value={country}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          State
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeState}
                          value={state}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Village
                        </label>
                        <input
                          type="text"
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeVillage}
                          value={village}
                        />
                      </div>
                    </div>
                  </div>

                  <hr className="mt-6 border-b-1 border-blueGray-300" />

                  <h6 className="text-blueGray-500 text-sm mt-3 mb-6 font-bold uppercase">
                    Payment Information
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Search or
                          <span
                            className="add-employer text-blue-500"
                            onClick={() => {
                              setShowAddEmployerModal(true);
                            }}
                          >
                            Add Employer
                          </span>
                        </label>
                        <div
                          style={{
                            backgroundColor: "white",
                            border: "0.01px solid white",
                            marginTop: 10,
                            borderRadius: 5,
                          }}
                        >
                          <Autocomplete
                            id="select_employer"
                            options={employers}
                            getOptionLabel={(employer) => employer.name} // is like a forEach to fill the select tag options
                            onChange={handleChangeEmployer}
                            // value --> default selected employer // "current employer"
                            // value={employers.find(
                            //   (emplo) => emplo.id === employer
                            // )}
                            value={autoCompleteEmployer}
                            style={{
                              marginTop: 4.5,
                              marginLeft: -1,
                              marginRight: -1,
                              marginBottom: -1,
                            }}
                            className="shadow-sm"
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Employer"
                                variant="outlined"
                                color="primary" // or secondary
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Start Date
                        </label>
                        <input
                          type="date"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeSartDate}
                          value={startDate}
                          disabled={editType === "update" && startDate != ""}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Status
                        </label>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={isActive}
                            onChange={handleChangeIsActive}
                          />
                          <label
                            class="form-check-label inline-block text-gray-800"
                            for="flexSwitchCheckDefault"
                          >
                            {isActive ? "Active" : "Stopped"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Bank name
                        </label>
                        <div
                          style={{
                            backgroundColor: "white",
                            border: "0.01px solid white",
                            marginTop: 10,
                            borderRadius: 5,
                          }}
                        >
                          <Autocomplete
                            id="select_bank"
                            options={bankslist}
                            getOptionLabel={(bank) => bank.name} // is like a forEach to fill the select tag options
                            onChange={handleChangeBankCode}
                            // value --> default selected bank // "current bank"
                            // value={bankslist.find(
                            //   (bank) => bank.code === bankCode
                            // )}
                            value={autoCompleteBank}
                            style={{
                              marginTop: 4.5,
                              marginLeft: -1,
                              marginRight: -1,
                              marginBottom: -1,
                            }}
                            className="shadow-sm"
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Bank"
                                variant="outlined"
                                color="primary" // or secondary
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Bank account number
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeBankAccountNumber}
                          value={bankAccountNumber}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Monthly Salary in {invoices_currency}
                        </label>
                        <input
                          type="number"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeSalary}
                          value={salary}
                        />
                      </div>
                    </div>
                  </div>

                  <hr className="mt-6 border-b-1 border-blueGray-300" />

                  <h6 className="text-blueGray-500 text-sm mt-3 mb-6 font-bold uppercase">
                    Other Information
                  </h6>
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          NIN / Passport number
                        </label>
                        <input
                          type="text"
                          required
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeNIN}
                          value={nin}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          required={editType === "create"}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeDOB}
                          value={dob}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-4/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Gender
                        </label>
                        <div class="form-check form-check-inline">
                          <input
                            className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="Male"
                            onChange={handleChangeGender}
                            checked={gender === "Male" ? true : false}
                          />
                          <label
                            className="form-check-label inline-block text-gray-800"
                            for="inlineRadio10"
                          >
                            Male
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="Female"
                            onChange={handleChangeGender}
                            checked={gender === "Female" ? true : false}
                          />
                          <label
                            className="form-check-label inline-block text-gray-800"
                            for="inlineRadio20"
                          >
                            Female
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Parent's name or guardian
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeParentGuardian}
                          value={parentGuardian}
                        />
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          LC 1 name
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          onChange={handleChangeLC1Name}
                          value={lc1Name}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-12/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Short description of bio data
                        </label>
                        <textarea
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          rows="3"
                          onChange={handleChangeBioData}
                          value={bioData}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center flex items-center justify-center mt-4">
                    <button
                      className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                      type="submit"
                      disabled={isSubmitButtonDisabled}
                    >
                      <span>
                        {editType === "update"
                          ? "Update Employee"
                          : "Create Employee"}
                      </span>
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
            <div className="profile-picture-container">
              <div className="profile-picture">
                <input
                  type="file"
                  ref={ref}
                  onChange={handleFileChange}
                  name="profileImage"
                  id="photoFile"
                  className="d-none"
                />
                <label
                  className="profile-button w-100 h-100"
                  role="button"
                  htmlFor="photoFile"
                >
                  <Image
                    src={profilePicture != "" ? profilePicture : avatar}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                    }}
                    alt="Loading..."
                  />
                  <div className="profile-image">
                    {" "}
                    <span> {imageStatus} </span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      {/* popup for add employer */}
      <Modal
        show={showAddEmployerModal}
        onHide={handleClose2}
        centered
        backdrop={true}
        size="md"
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#eee", fontSize: 40 }}
        >
          <Modal.Title>Create New Employer</Modal.Title>
        </Modal.Header>
        <>
          <div className="relative flex flex-col min-w-0 break-words w-full shadow-md rounded-xl bg-blueGray-100 border-0">
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={createEmployer}>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Employer Details
                </h6>

                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 pl-2 pr-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={newEmployerName}
                        onChange={handleChangeNewEmployerName}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 pl-2 pr-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        {"Phone (add country code) "}
                        <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={newEmployerPhoneNumber}
                        onChange={handleChangeNewEmployerPhoneNumber}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 pl-2 pr-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="email"
                        required
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={newEmployerEmail}
                        onChange={handleChangeNewEmployerEmail}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 pl-2 pr-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Country <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={newEmployerCountry}
                        onChange={handleChangeNewEmployerCountry}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 pl-2 pr-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={newEmployerAddress}
                        onChange={handleChangeNewEmployerAddress}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 pl-2 pr-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        State
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={newEmployerState}
                        onChange={handleChangeNewEmployerState}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center flex items-center justify-center mt-4">
                  <button
                    className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                    type="submit"
                    disabled={isSubmitButtonDisabled2}
                  >
                    <span>Create Employer</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      </Modal>
      {/* popup for add employer */}
    </>
  );
}

export default withRouter(EditEmployee);
