import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  showPopup,
  updateEmployerToStore,
  employerUpdated as employerUpdatedAction,
  employerAdded as employerAddedAction,
  addemployerToStore,
} from "../../actions";
import ApiCalls from "../../api/apiCalls";
import { toast } from "react-toastify";

function EditEmployer({ history }) {
  const dispatch = useDispatch();
  const showEditEmployerPopup = useSelector(
    ({ showEditEmployerPopup }) => showEditEmployerPopup
  );
  const authToken = useSelector(({ userData: { token } }) => token);
  const employers = useSelector(({ records: { employers } }) => employers);
  const editEmployer = useSelector(({ editEmployer }) => editEmployer);
  const employerUpdated = useSelector(({ employerUpdated }) => employerUpdated);
  const employerAdded = useSelector(({ employerAdded }) => employerAdded);
  const [editType, setEditType] = useState("update");
  // employer form fields
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  // submit button
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  let api = new ApiCalls();

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeAddress = (event) => {
    setAddress(event.target.value);
  };
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleChangeState = (event) => {
    setState(event.target.value);
  };

  const handleClose = () => {
    dispatch(showPopup("edit_employer", false));
  };

  const saveEmployer = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmitButtonDisabled(true);
    toast.promise(
      editType === "update"
        ? api.updateEmployer(
            {
              name: name,
              email: email,
              phoneNumber: phoneNumber,
              address: address,
              country: country,
              state: state,
            },
            editEmployer.employerId,
            authToken
          )
        : api.createEmployer(
            {
              name: name,
              email: email,
              phoneNumber: phoneNumber,
              address: address,
              country: country,
              state: state,
            },
            authToken
          ),
      {
        pending: "Please wait...",
        success: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.status === 200) {
              let responseBody = data.data;
              console.log(responseBody.message);

              if (editType === "update") {
                dispatch(updateEmployerToStore(responseBody.data));
                dispatch(employerUpdatedAction());
              } else if (editType === "create") {
                dispatch(addemployerToStore(responseBody.data));
                dispatch(employerAddedAction());
              }

              handleClose();
              return `${responseBody.message}`;
            }
          },
        },
        error: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.response) {
              console.log("status code " + data.response.status);
              let { message } = data.response.data;
              console.log("errorMessage:", message);
              return `${message}`;
            } else {
              console.log("An error occurred, try again later");
              return "An error occurred, try again later";
            }
          },
        },
      }
    );
  };

  useEffect(() => {
    if (editEmployer.employerId != null) {
      let employer = employers.find(
        (employer) => employer.id === editEmployer.employerId
      );

      setEditType("update"); // update fields

      setName(employer.name);
      setEmail(employer.email);
      setPhoneNumber(employer.phoneNumber != null ? employer.phoneNumber : "");
      setAddress(employer.address != null ? employer.address : "");
      setCountry(employer.country != null ? employer.country : "");
      setState(employer.state != null ? employer.state : "");
    } else {
      setEditType("create"); // create new employer // empty fields

      setName("");
      setEmail("");
      setPhoneNumber("");
      setAddress("");
      setCountry("");
      setState("");
      // ...
    }

    // cleanup this component // when component unmounts // run this callback
    return () => {
      setName("");
      setEmail("");
      setPhoneNumber("");
      setAddress("");
      setCountry("");
      setState("");
    };
  }, [editEmployer, employerAdded, employerUpdated]);

  return (
    <Modal
      show={showEditEmployerPopup}
      onHide={handleClose}
      centered
      backdrop={false}
      size="xl"
    >
      <form onSubmit={saveEmployer}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#eee",
            fontSize: 40,
            position: "sticky",
            top: 0,
            zIndex: 2,
          }}
        >
          {/* <Modal.Title>
          {editType === "update" ? "Update Employer" : "Create Employer"}
        </Modal.Title> */}
          <button
            className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center text-sm focus:outline-none transition hover:shadow-md shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
            disabled={isSubmitButtonDisabled}
            type="submit"
          >
            <span>
              {editType === "update" ? "Update Employer" : "Save Employer"}
            </span>
          </button>
        </Modal.Header>
        <div className="relative flex flex-col min-w-0 break-words w-full bg-blueGray-200 border-0 ">
          <div className="flex-auto px-4 lg:px-10 pb-10 pt-0">
            {/* <form onSubmit={saveEmployer}> */}
            <h6 className="text-blueGray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Employer Details
            </h6>

            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeName}
                    value={name}
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    {"Phone Number (add country code)"}
                  </label>
                  <input
                    type="tel"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangePhoneNumber}
                    value={phoneNumber}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeEmail}
                    value={email}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-500 text-sm mt-3 mb-6 font-bold uppercase">
              Address Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Kansanga Ggabba road, 248"
                    onChange={handleChangeAddress}
                    value={address}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeCountry}
                    value={country}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeState}
                    value={state}
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center flex items-center justify-center mt-4">
              <button
                className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                type="submit"
                disabled={isSubmitButtonDisabled}
              >
                <span>
                  {editType === "update"
                    ? "Update Employer"
                    : "Create Employer"}
                </span>
              </button>
            </div>
            {/* </form> */}
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default withRouter(EditEmployer);
