import { MDBContainer, MDBDataTable } from "mdbreact";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showCommissionToStore, showPopup } from "../../../actions";
import CreateCommission from "../../../utils/popups/createCommission";
import Agencies from "../../../utils/popups/agencies";
import { ToastContainer } from "react-toastify";
import { Col, Row } from "react-bootstrap";

const Status = ({ status }) => {
  const [showTextStatus, setShowTextStatus] = useState(false);
  let badge =
    status === "paid"
      ? "green-500"
      : status === "withdrawn"
      ? "purple-500"
      : status === "transfer failed"
      ? "red-500"
      : status === "transfering"
      ? "yellow-500"
      : status === "initiated"
      ? "blue-500"
      : "blue-500";

  useEffect(() => {
    if (window.innerWidth >= 775) {
      setShowTextStatus(false);
    } else {
      setShowTextStatus(true);
    }
    const handleResize = (event) => {
      //var width = window.innerWidth;
      if (window.innerWidth >= 775) {
        setShowTextStatus(false);
      } else {
        setShowTextStatus(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Row
      style={{
        color: "black",
        cursor: "pointer",
      }}
      className={`table-tbody`}
    >
      <Col
        md={2}
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "cursive",
          paddingTop: 5,
        }}
      >
        <div
          style={{
            width: 16,
            height: 16,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // boxShadow: "2px 2px",
          }}
          className={`bg-${badge} text-${badge}`}
          title={status}
        >
          oo
        </div>
      </Col>
      <Col hidden={showTextStatus}>{status.toUpperCase()}</Col>
    </Row>
  );
};

const Label = ({ label }) => {
  return (
    <span style={{ cursor: "pointer" }}>
      {label}
      <span
        class="text-blueGray-500 "
        style={{
          marginLeft: 4,
        }}
      >
        ↑↓
      </span>
    </span>
  );
};

function Commissions({ history }) {
  const [commissionsDataTable, setCommissionsDataTable] = useState({});
  const commissions = useSelector(
    ({ records: { commissions } }) => commissions
  );
  const user_data = useSelector((state) => state.userData.user_data);
  const agencies = useSelector(({ records: { agencies } }) => agencies); // has agency name,..
  const payments = useSelector(({ records: { payments } }) => payments); // has transactionId,...
  const invoices = useSelector(({ records: { invoices } }) => invoices);
  const employees = useSelector(({ records: { employees } }) => employees);
  const commissionsAdded = useSelector(
    ({ commissionsAdded }) => commissionsAdded
  );
  const commissions_currency = useSelector(
    ({ records: { commissions_currency } }) => commissions_currency
  );
  const invoices_currency = useSelector(
    ({ records: { invoices_currency } }) => invoices_currency
  );
  const [scrollY, setScrollY] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const displayData = commissions.reverse().map((commission) => {
      let agency = agencies.find((agency) => agency.id === commission.agency);
      let invoice = invoices.find(
        (invoice) => invoice.id === commission.invoice
      );
      let employee, payment;
      if (invoice != undefined) {
        employee = employees.find(
          (employee) => employee.id === invoice.employee
        );
        payment = payments.find((payment) => payment.id === invoice.payment);
      }

      return {
        status: <Status status={commission.status} />,
        statusSearchable: commission.status,
        createdAt: new Date(commission.createdAt).toLocaleString(
          "en-UG",
          options
        ),
        amount:
          commission.amount != null
            ? `${commissions_currency} ${Number.parseInt(
                commission.amount
              ).toLocaleString()}`
            : "",
        agency: agency.name,
        payment:
          payment != undefined ? payment.transactionId.toLowerCase() : "",
        employee:
          employee != null
            ? `${employee.first_name} ${employee.last_name}`
            : "",
        clickEvent: () => {
          dispatch(
            showCommissionToStore({
              commission: {
                id: commission.id,
                amount:
                  commission.amount != null
                    ? `${commissions_currency} ${Number.parseInt(
                        commission.amount
                      ).toLocaleString()}`
                    : "",
                status: commission.status,
                createdAt: new Date(commission.createdAt).toLocaleString(
                  "en-UG",
                  options
                ),
              },
              agency: {
                id: agency.id,
                name: agency.name,
                phoneNumber: agency.phoneNumber,
                bankName: agency.bankName,
              },
              payment: {
                id: payment != undefined ? payment.id : "",
                amount: payment != undefined ? payment.amount : "",
                transactionFee:
                  payment != undefined ? payment.transactionFee : "",
                transactionId:
                  payment != undefined ? payment.transactionId : "",
                status: "",
                comment: payment != undefined ? payment.comment : "",
              },
              invoice: {
                id: invoice != undefined ? commission.invoice : "",
                amount:
                  invoice != undefined
                    ? `${
                        invoices_currency === "USD" ? "$" : invoices_currency
                      } ${Number.parseInt(invoice.amount).toLocaleString()}`
                    : "",
                status: invoice != undefined ? invoice.status : "",
              },
              employee: {
                name:
                  employee != null
                    ? `${employee.first_name} ${employee.last_name}`
                    : "",
                id: employee != null ? employee.id : "",
                monthlySalary: employee != null ? employee.monthlySalary : "",
              },
            })
          );
          history.push(`/dashboard/commission/${commission.id}`);
        },
      };
    });

    setCommissionsDataTable({
      columns: [
        {
          label: <Label label="Agency" />,
          field: "agency",
          sort: "asc",
          width: 180,
        },
        {
          label: <Label label={"Amount"} />,
          field: "amount",
          sort: "asc",
          width: 165,
        },
        {
          label: <Label label="Reference" />,
          field: "payment",
          sort: "asc",
          width: 165,
        },
        {
          label: <Label label="Status" />,
          field: "status",
          sort: "asc",
          width: 150,
        },
        {
          label: <Label label="Date" />,
          field: "createdAt",
          sort: "asc",
          width: 240,
        },
      ],
      rows: displayData,
    });
  }, [commissions, commissionsAdded]);

  useEffect(() => {
    if (window.innerWidth >= 775) {
      setScrollY(false);
    } else {
      setScrollY(true);
    }
    const handleResize = (event) => {
      var width = window.innerWidth;
      if (width >= 775) {
        setScrollY(false);
      } else {
        setScrollY(true);
      }
    };
    window.addEventListener("resize", handleResize);

    // cleanup this component // when component unmounts // run this callback
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // runs only once // empty dependency list

  return (
    <>
      <div>
        <div className="div-employees-page-title">
          <span
            className="employees-page-title text-indigo-500"
            style={{
              fontWeight: "lighter",
              wordSpacing: "4px",
              letterSpacing: "1px",
              lineHeight: 1.8,
            }}
          >
            Commissions
          </span>
          <button
            hidden={user_data.groups[0] != 3} // only supersite
            style={{
              fontWeight: "lighter",
              wordSpacing: "1px",
              lineHeight: 1,
              marginLeft: "8vw",
            }}
            class="bg-indigo-500 hover:bg-blueGray-500 text-white font-bold py-2 px-3 rounded-full"
            onClick={() => {
              dispatch(showPopup("select_agency", true));
            }}
          >
            <i class="fa fa-plus" aria-hidden="true" />
            New Commission
          </button>
        </div>
        <div className="padding-data-table padding-for-bottom-navigation">
          <MDBContainer
            className="shadow-sm"
            style={{
              backgroundColor: "white",
              paddingTop: 10,
              borderRadius: 10,
              paddingBottom: 10,
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            <MDBDataTable
              borderless
              maxHeight="100vh"
              hover
              responsive
              responsiveSm
              responsiveMd
              responsiveLg
              responsiveXl
              theadColor="#5072a7"
              data={commissionsDataTable}
              id="tableID"
              exportToCSV
              noBottomColumns
              pagesAmount={3}
              entriesOptions={[20, 50, 100]}
            />
          </MDBContainer>
        </div>
        {/* <div
          className="create-invoice-btn"
          hidden={user_group_data.type === "agency"}
        >
          <div
            className="bg-indigo-900 shadow-lg"
            style={{
              position: "fixed",
              padding: 15,
              zIndex: 3,
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(showPopup("select_agency", true));
            }}
          >
            Create Commission
          </div>
        </div> */}
      </div>
      {/* popup for Employees */}
      <Agencies />
      {/* popup for create commission */}
      <CreateCommission />
      <ToastContainer />
    </>
  );
}

export default Commissions;
