import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";

import { Modal, Button } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { showPopup } from "../../actions";
import ApiCalls from "../../api/apiCalls";
import { toast } from "react-toastify";

function VerifyOTP({ history }) {
  const [otp, setOTP] = useState("");
  const [hasErrors, setHasErrors] = useState(false);
  const dispatch = useDispatch();
  const showOTPPopup = useSelector(({ showOTPPopup }) => showOTPPopup);
  const phoneNumber = useSelector(
    ({
      userData: {
        user_data: {
          user_group_data: { phoneNumber },
        },
      },
    }) => phoneNumber
  );
  const authToken = useSelector(({ userData: { token } }) => token);
  const groupId = useSelector(
    ({
      userData: {
        user_data: {
          groups: [groupId], // first element in the array --> groupId
        },
      },
    }) => groupId
  );

  let api = new ApiCalls();

  const handleClose = () => {
    dispatch(showPopup("verify_otp", false));
  };

  const handleChangeOTP = (otp) => {
    setOTP(otp);
  };

  const checkingCode = () => toast.loading("Checking the code...");
  const checkingCode2 = () => toast.success("OTP is correct");

  const verifyPhone = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    // checkingCode();
    // TODO: call api to verify phone number
    toast.promise(api.verifyOTP(groupId, otp, authToken), {
      pending: "Checking the code...",
      success: {
        render({ data }) {
          if (data.status === 200) {
            let responseBody = data.data;
            console.log(responseBody.message);
            localStorage.setItem(
              `${process.env.REACT_APP_ACCESS_TOKEN_KEY}`,
              authToken
            );
            history.push("/dashboard");
            handleClose();
            return `${responseBody.message}`;
          }
        },
      },
      error: {
        render({ data }) {
          setHasErrors(true);
          if (data.response) {
            console.log("status code " + data.response.status);
            let { message } = data.response.data;
            console.log("errorMessage:", message);
            return `${message}`;
          } else {
            console.log("An error occurred, try again later");
            return "An error occurred, try again later";
          }
        },
      },
    });
    /*
    try {
      let response = await api.verifyOTP(groupId, otp, authToken);
      if (response.status === 200) {
        let responseBody = response.data;
        console.log(responseBody.message);
        history.push("/dashboard");
        handleClose();
      }
    } catch (error) {
      setHasErrors(true);
      if (error.response) {
        console.log("status code " + error.response.status);
        let { message } = error.response.data;
        console.log("errorMessage:", message);
        alert(message);
      } else {
        console.log("An error occurred, try again later");
        alert("An error occurred, try again later");
      }
    }
    */
  };

  const resendOTP = () => {
    // TODO: call api to resend the OTP to the phone number
  };

  return (
    <Modal show={showOTPPopup} onHide={handleClose} centered backdrop={false}>
      <Modal.Header
        closeButton
        style={{ backgroundColor: "#eee", fontSize: 40 }}
      >
        <Modal.Title>Verify Phone Number</Modal.Title>
      </Modal.Header>
      <form onSubmit={verifyPhone}>
        <Modal.Body>
          <div className="text-center text-blueGray-700">
            Enter the code we have sent to {phoneNumber}
          </div>
          <div className="flex items-center justify-center pt-5 pb-5">
            <OtpInput
              value={otp}
              onChange={handleChangeOTP}
              numInputs={4}
              separator={<span style={{ padding: 5 }}>-</span>}
              shouldAutoFocus
              hasErrored={hasErrors}
              inputStyle={{
                border: "1px solid gray",
                borderRadius: 3,
                height: 50,
                width: 40,
                fontSize: 20,
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            //variant="secondary"
            onClick={handleClose}
            style={{
              backgroundColor: "#a6a6a6",
              border: "0px",
              color: "white",
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" style={{ color: "white" }}>
            Verify
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default withRouter(VerifyOTP);
