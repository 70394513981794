import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import ApiCalls from "../../api/apiCalls";
import Loader from "../Loader";

export const LogoutRoute = ({ component: RouteComponent, ...rest }) => {
  const storedToken = localStorage.getItem(
    `${process.env.REACT_APP_ACCESS_TOKEN_KEY}`
  );
  const [isLoading, setIsLoading] = useState(true);
  let api = new ApiCalls();

  useEffect(async () => {
    if (storedToken != null) {
      try {
        let response = await api.logoutUser(storedToken);
        if (response.status === 200) {
          setIsLoading(false);
          let responseBody = response.data;
          console.log(responseBody.message);
          // console.log(responseBody.data);
          localStorage.removeItem(`${process.env.REACT_APP_ACCESS_TOKEN_KEY}`);
        }
      } catch (error) {
        if (error.response) {
          console.log("status code " + error.response.status);
          let { message } = error.response.data;
          console.log("errorMessage:", message);
          // alert(message);
        } else {
          console.log("An error occurred. Please try again later");
        }
      }
    }
  }, [storedToken]);

  return (
    isLoading ? <Loader /> : (
      <Route
        {...rest}
        render={(routeProps) => {
          if (storedToken != null) {
            return <RouteComponent {...routeProps} />;
          } else {
            return <Redirect to={"/"} />; // to home
          }
        }}
      />
    )
  );
};
