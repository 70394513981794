import types from "../actions/types/types";

export const createOrUpdateAgencyReducer = (
  state = {
    agencyId: null,
  },
  action
) => {
  switch (action.type) {
    case types.CREATE_OR_UPDATE_AGENCY:
      return action.payload;
    default:
      return state;
  }
};
