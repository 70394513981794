import { combineReducers } from "redux";
import {
  agencyAddedOrUpdatedReducer,
  commissionAddedReducer,
  employeeAddedOrUpdatedReducer,
  employerAddedReducer,
  employerUpdatedReducer,
  invoiceAddedReducer,
  recordsReducer,
  setBanksListReducer,
} from "./records";
import { userDataReducer } from "./user";
import {
  verifyOTPPopupReducer,
  createInvoicePopupReducer,
  createCommissionPopupReducer,
  selectEmployeePopupReducer,
  selectAgencyPopupReducer,
  editEmployeePopupReducer,
  editAgencyPopupReducer,
  editEmployerPopupReducer,
  showInvoicePopupReducer,
  editUserPopupReducer,
} from "./show_popup";
import { createInvoiceReducer } from "./create_invoice";
import { createCommissionReducer } from "./create_commission";
import { createOrUpdateEmployeeReducer } from "./create_or_update_employee";
import { createOrUpdateAgencyReducer } from "./create_or_update_agency";
import { createOrUpdateEmployerReducer } from "./create_or_update_employer";
import { createCommissionDataReducer } from "./create_commission_data";
import { createInvoiceDataReducer } from "./create_invoice_data";

const allReducers = combineReducers({
  userData: userDataReducer,
  records: recordsReducer,
  showOTPPopup: verifyOTPPopupReducer,
  showCreateInvoicePopup: createInvoicePopupReducer,
  showCreateCommissionPopup: createCommissionPopupReducer,
  showSelectEmployeePopup: selectEmployeePopupReducer,
  showSelectAgencyPopup: selectAgencyPopupReducer,
  showEditEmployeePopup: editEmployeePopupReducer, //
  showEditAgencyPopup: editAgencyPopupReducer, //
  showEditEmployerPopup: editEmployerPopupReducer, //
  newInvoice: createInvoiceReducer,
  showInvoiceData: createInvoiceDataReducer,
  newCommission: createCommissionReducer,
  editEmployee: createOrUpdateEmployeeReducer, //
  editAgency: createOrUpdateAgencyReducer, //
  editEmployer: createOrUpdateEmployerReducer, //
  invoicesAdded: invoiceAddedReducer,
  commissionsAdded: commissionAddedReducer,
  employeeAddedOrUpdated: employeeAddedOrUpdatedReducer, //
  agencyAddedOrUpdated: agencyAddedOrUpdatedReducer, //
  employerAdded: employerAddedReducer, //
  employerUpdated: employerUpdatedReducer, //
  bankslist: setBanksListReducer,
  showCommissionData: createCommissionDataReducer,
  showInvoice: showInvoicePopupReducer,
  showEditUserPopup: editUserPopupReducer,
});

export default allReducers;
