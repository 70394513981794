import types from "../actions/types/types";

export const createCommissionReducer = (
  state = {
    agency: {
      id: "",
      name: "",
      phoneNumber: "",
      bankName: "",
    },
  },
  action
) => {
  switch (action.type) {
    case types.CREATE_COMMISSION:
      return action.payload;
    default:
      return state;
  }
};
