import React, { useState, useEffect } from "react";
import "../../styles/output.css"; // tailwindcss styles on this component
import "../../styles/login.css";
import { withRouter } from "react-router";
import ApiCalls from "../../api/apiCalls";
import { useDispatch } from "react-redux";
import { setUserData, showPopup } from "../../actions";
import { toast } from "react-toastify";

const Login = ({ history }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [title, setTitle] = useState("");
  let api = new ApiCalls();
  const dispatch = useDispatch();
  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    if (window.innerWidth >= 775) {
      setTitle("Username or Email");
    } else {
      setTitle("Username");
    }
    const handleResize = (event) => {
      if (window.innerWidth >= 775) {
        setTitle("Username or Email");
      } else {
        setTitle("Username");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const signin = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmitButtonDisabled(true);
    // TODO: verify validation
    // call the api
    toast.promise(
      api.login({
        username: username,
        password: password,
      }),
      {
        pending: "Checking user details",
        success: {
          render({ data }) {
            if (data.status === 200) {
              let responseBody = data.data;
              console.log(responseBody.message);
              dispatch(setUserData(responseBody.data));
              dispatch(showPopup("verify_otp", true));
              setIsSubmitButtonDisabled(false);
              return `${responseBody.message}`;
            }
          },
        },
        error: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.response) {
              let { message } = data.response.data;
              console.log("errorMessage:", message);
              return `${message}`;
            } else {
              console.log("An error occurred, try again later");
              return "An error occurred, try again later";
            }
          },
        },
      }
    );
    /*
    try {
      let loginResponse = await api.login({
        username: username,
        password: password,
      });
      if (loginResponse.status === 200) {
        let responseBody = loginResponse.data;
        console.log(responseBody.message);
        dispatch(setUserData(responseBody.data));
        dispatch(showPopup("verify_otp", true));
      }
    } catch (error) {
      if (error.response) {
        let { message } = error.response.data;
        console.log("errorMessage:", message);
        alert(message);
      } else {
        console.log("An error occurred. Please try again later");
        alert("An error occurred. Please try again later");
      }
    }
    */
  };
  return (
    <div
      className="relative min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 bg-transparent bg-no-repeat bg-cover relative items-center"
      //className="background-image: url(https://images.unsplash.com/photo-1624916888581-48904076264b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80);"
    >
      {/* <div className="absolute bg-black opacity-60 inset-0 z-0"></div> */}
      <div className="mt-2 items-center z-10">
        <form
          className="p-14 bg-white max-w-sm mx-auto rounded-xl shadow-xl overflow-hidden p-6 space-y-10"
          onSubmit={signin}
          autoComplete="off"
        >
          <h2 className="text-4xl font-bold text-center text-indigo-500">
            Login
          </h2>
          <div className="f-outline px-2 relative border rounded-lg focus-within:border-indigo-500">
            <input
              type="text"
              name="username"
              required
              placeholder=" "
              style={{ color: "black" }}
              onChange={handleChangeUsername}
              value={username}
              className="block p-2 w-full text-lg appearance-none focus:outline-none bg-transparent"
            />
            <label
              for="username"
              className="absolute ml-5 top-0 text-lg text-gray-700 bg-white mt-2 -z-1 duration-300 origin-0"
            >
              {title}
            </label>
          </div>
          <div className="f-outline px-2 relative border rounded-lg focus-within:border-indigo-500 mt-4">
            <input
              type="password"
              name="password"
              placeholder=" "
              required
              onChange={handleChangePassword}
              value={password}
              style={{ color: "black" }}
              className="block p-2 w-full text-lg appearance-none focus:outline-none bg-transparent"
            />
            <label
              for="password"
              className="absolute ml-5 top-0 text-lg text-gray-700 bg-white mt-2 -z-1 duration-300 origin-0"
            >
              Password
            </label>
          </div>
          <div class="block mt-2">
            <label for="" class="flex items-center">
              <input
                type="checkbox"
                className="ml-2 rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              ></input>
              <span className="ml-2 text-sm text-gray-600">Remember me</span>
            </label>
          </div>
          <div className="flex items-center flex items-center justify-center mt-4">
            <a
              className="underline text-sm text-gray-600 hover:text-gray-900"
              href="/reset-password"
            >
              Forgot Password?
            </a>
            <button
              className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-700 rounded-full text-white bg-indigo-500"
              type="submit"
              disabled={isSubmitButtonDisabled}
            >
              <span>Login</span>
            </button>
          </div>
          <div class="block mt-3 items-center justify-center">
            <span
              className="ml-2 text-sm text-gray-600"
              style={{ paddingRight: 3 }}
            >
              Don't have an account?
            </span>
            <a
              className="underline text-sm text-indigo-600 hover:text-gray-900"
              href="/signup"
            >
              Sign up
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Login);
