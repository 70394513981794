import types from "../actions/types/types"

const initialStateReducer = {
  commission: {
    id: "",
    amount: "",
    status: "",
    createdAt: "",
  },
  agency: {
    id: "",
    name: "",
    phoneNumber: "",
    bankName: "",
  },
  payment: {
    id: "",
    amount: "",
    transactionFee: "",
    transactionId: "",
    status: "",
    comment: "",
  },
  invoice: {
    id: "",
    amount: "",
    status: "",
  },
  employee: {
    name: "",
    id: "",
    monthlySalary: "",
  },
}

export const createCommissionDataReducer = (state = initialStateReducer, action) => {
  switch(action.type) {
    case types.SHOW_COMMISSION_TO_STORE:
      return action.payload;
    default:
      return state;
  }
}