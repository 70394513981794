import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import FooterPagePro from "../utils/footer/footer";
import check_svg from "../images/thin_with_steps-svg1.svg";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import ApiCalls from "../api/apiCalls";

const ForgotPassword = () => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [resetCode, setResetCode] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [type, setType] = useState("");
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isSMSChecked, setIsSMSChecked] = useState(false);

  const [step, setStep] = useState(1);
  const [authToken, setAuthToken] = useState("");

  let api = new ApiCalls();

  const handleChangeEmailType = (event) => {
    setType(event.target.value);
    setIsEmailChecked(true);
    setIsSMSChecked(false);
  };
  const handleChangeSMSType = (event) => {
    setType(event.target.value);
    setIsSMSChecked(true);
    setIsEmailChecked(false);
  };
  const handleChangeResetCode = (code) => {
    setResetCode(code);
  };
  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const sendResetCode = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (type === "") {
      toast("Please select an reset option!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "warning",
      });
      return;
    }

    setIsSubmitButtonDisabled(true);

    toast.promise(
      api.sendResetCode({
        username: username,
        type: type,
      }),
      {
        pending: "Checking user...",
        success: {
          render({ data }) {
            if (data.status === 200) {
              let responseBody = data.data;
              console.log(responseBody.message);
              setStep(2);
              setIsSubmitButtonDisabled(false);
              return `${responseBody.message}`;
            }
          },
        },
        error: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.response) {
              console.log("status code " + data.response.status);
              let { message } = data.response.data;
              console.log("errorMessage:", message);
              return `${message}`;
            } else {
              console.log("An error occurred, try again later");
              return "An error occurred, try again later";
            }
          },
        },
      }
    );
  };

  const verifyResetCode = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (resetCode === "") {
      toast("Please enter the code!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "warning",
      });
      return;
    }

    setIsSubmitButtonDisabled(true);

    toast.promise(api.verifyResetCode(username, resetCode), {
      pending: "Verifying the code...",
      success: {
        render({ data }) {
          if (data.status === 200) {
            let responseBody = data.data;
            console.log(responseBody.message);
            let { token } = responseBody.data;
            setAuthToken(token);
            setStep(3);
            setIsSubmitButtonDisabled(false);
            return `${responseBody.message}`;
          }
        },
      },
      error: {
        render({ data }) {
          setHasErrors(true);
          setIsSubmitButtonDisabled(false);
          if (data.response) {
            console.log("status code " + data.response.status);
            let { message } = data.response.data;
            console.log("errorMessage:", message);
            return `${message}`;
          } else {
            console.log("An error occurred, try again later");
            return "An error occurred, try again later";
          }
        },
      },
    });
  };

  const updatePassword = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (password.length < 6) {
      toast("Password must have at least 6 characters!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "warning",
      });
      return;
    }

    setIsSubmitButtonDisabled(true);

    toast.promise(
      api.changePassword(
        {
          new_password: password,
        },
        authToken
      ),
      {
        pending: "Saving password...",
        success: {
          render({ data }) {
            if (data.status === 200) {
              let responseBody = data.data;
              console.log(responseBody.message);
              setStep(4);
              setIsSubmitButtonDisabled(false);
              return `${responseBody.message}`;
            }
          },
        },
        error: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.response) {
              console.log("status code " + data.response.status);
              let { message } = data.response.data;
              console.log("errorMessage:", message);
              return `${message}`;
            } else {
              console.log("An error occurred, try again later");
              return "An error occurred, try again later";
            }
          },
        },
      }
    );
  };

  return (
    <div className="bg-blueGray-100" style={{ minHeight: 500 }}>
      <div
        style={{
          minHeight: 60,
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}
        className="bg-indigo-500"
      >
        <Row style={{ minHeight: 60 }}>
          <Col
            lg={8}
            md={8}
            sm={6}
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <span
              style={{
                fontWeight: "lighter",
                wordSpacing: "3px",
                letterSpacing: "1px",
                paddingLeft: 20,
              }}
              className="company-title text-white"
            >
              Cheque-Handler
            </span>
          </Col>
          <Col
            lg={4}
            md={4}
            sm={6}
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <a href="/" className="text-white">
              <button class="hover:bg-white font-semibold hover:text-indigo-500 py-2 px-4 border border-white-500 hover:border-transparent rounded">
                Login
              </button>
            </a>
          </Col>
        </Row>
      </div>
      <Container
        style={{
          minHeight: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          class={`w-1/3 ${
            step >= 2 ? "bg-indigo-500" : "bg-gray-300"
          } h-1 flex items-center`}
        >
          <div
            class={`${
              step >= 1 ? "bg-indigo-500" : "bg-gray-300"
            } h-6 w-6 rounded-full shadow flex items-center justify-center`}
          >
            <img src={check_svg} alt="check" />
          </div>
        </div>
        <div
          class={`w-1/3 ${
            step >= 3 ? "bg-indigo-500" : "bg-gray-300"
          } h-1 flex items-center`}
        >
          <div
            class={`${
              step >= 2 ? "bg-indigo-500" : "bg-gray-300"
            } h-6 w-6 rounded-full shadow flex items-center justify-center`}
          >
            <img src={check_svg} alt="check" />
          </div>
        </div>
        <div
          class={`w-1/3 ${
            step >= 4 ? "bg-indigo-500" : "bg-gray-300"
          } h-1 flex items-center`}
        >
          <div
            class={`${
              step >= 3 ? "bg-indigo-500" : "bg-gray-300"
            } h-6 w-6 rounded-full shadow flex items-center justify-center`}
          >
            <img src={check_svg} alt="check" />
          </div>
        </div>
        <div
          class={`${
            step >= 4 ? "bg-indigo-500" : "bg-gray-300"
          } h-1 flex items-center`}
        >
          <div
            class={`${
              step >= 4 ? "bg-indigo-500" : "bg-gray-300"
            } h-6 w-6 rounded-full shadow flex items-center justify-center`}
          >
            <img src={check_svg} alt="check" />
          </div>
        </div>
      </Container>
      <Container
        style={{
          minHeight: 200,
        }}
        hidden={step != 1}
      >
        <div>
          <h4>Step1: Choose reset option</h4>
        </div>
        <div className="flex items-center justify-center pt-4">
          <form onSubmit={sendResetCode}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    SEND CODE BY:
                  </label>
                  <div class="form-check form-check-inline">
                    <input
                      className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="email"
                      onChange={handleChangeEmailType}
                      checked={isEmailChecked}
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      for="inlineRadio10"
                    >
                      Email
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="phone"
                      onChange={handleChangeSMSType}
                      checked={isSMSChecked}
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      for="inlineRadio20"
                    >
                      SMS
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email or Username <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeUsername}
                    value={username}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center flex items-center mt-2 mb-8 px-4">
              <button
                className="px-6 py-2 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                type="submit"
                disabled={isSubmitButtonDisabled}
              >
                <span>Send Code</span>
              </button>
            </div>
          </form>
        </div>
      </Container>
      <Container
        style={{
          minHeight: 200,
        }}
        hidden={step != 2}
      >
        <div>
          <h4>Step2: Enter the received code</h4>
        </div>
        <div className="flex items-center justify-center pt-4">
          <form onSubmit={verifyResetCode}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Code <span className="text-red-600">*</span>
                  </label>
                  <OtpInput
                    value={resetCode}
                    onChange={handleChangeResetCode}
                    numInputs={6}
                    separator={<span style={{ padding: 3 }}>-</span>}
                    shouldAutoFocus
                    hasErrored={hasErrors}
                    inputStyle={{
                      border: "1px solid gray",
                      borderRadius: 3,
                      height: 40,
                      width: 30,
                      fontSize: 16,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center flex items-center mt-2 mb-8 px-4">
              <button
                className="px-6 py-2 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                type="submit"
                disabled={isSubmitButtonDisabled}
              >
                <span>Verify Code</span>
              </button>
            </div>
          </form>
        </div>
      </Container>
      <Container
        style={{
          minHeight: 200,
        }}
        hidden={step != 3}
      >
        <div>
          <h4>Step3: Enter new password</h4>
        </div>
        <div className="flex items-center justify-center pt-4">
          <form onSubmit={updatePassword}>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    New Password <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="password"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangePassword}
                    value={password}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center flex items-center mt-2 mb-8 px-4">
              <button
                className="px-6 py-2 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                type="submit"
                disabled={isSubmitButtonDisabled}
              >
                <span>Change Password</span>
              </button>
            </div>
          </form>
        </div>
      </Container>
      <Container
        style={{
          minHeight: 200,
        }}
        hidden={step != 4}
      >
        <div>
          <h4>Step4: Finished!</h4>
        </div>
        <div className="flex items-center justify-center pt-4">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-12/12 pl-4 pr-2">
              <div className="relative w-full mb-3">
                <label
                  className="block text-blueGray-600 text-lg font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Your are all set!
                </label>
              </div>
            </div>
          </div>
          <div className="flex items-center flex items-center mt-2 mb-8 px-4">
            <a href="/" style={{ textDecoration: "none" }}>
              <button className="px-6 py-2 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600">
                <span>Login now</span>
              </button>
            </a>
          </div>
        </div>
      </Container>
      <FooterPagePro />
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
