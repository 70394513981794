import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

export const HomePageRoute = ({ component: RouteComponent, ...rest }) => {
  const storedToken = localStorage.getItem(
    `${process.env.REACT_APP_ACCESS_TOKEN_KEY}`
  );

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (storedToken != null) {
          return <Redirect to={"/dashboard"} />; // to dashboard
        } else {
          return <RouteComponent {...routeProps} />;
        }
      }}
    />
  );
};
