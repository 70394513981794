const ContactUs = () => {
  return (
    <div
      style={{
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        fontSize: 30,
      }}
    >
      Contact us
      <br />
      <span style={{ fontSize: 15 }}>coming soon...</span>
    </div>
  );
};

export default ContactUs;
