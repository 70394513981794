import React from 'react';
import { Circles } from 'react-loader-spinner';

const Loader = () => {
  return (
    <div className="loader d-flex justify-content-center align-items-center" 
      style={{
        minHeight: "90.9vh",
        height: "93.8vh", 
      }}
    >
      <Circles color="#3B82F6" height={80} width={80} ariaLabel='loading' />
    </div>
  );
}

export default Loader;
