import types from "./types/types";

export const setRecords = (payload) => {
  return { type: types.SET_RECORDS, payload: payload };
};

export const addInvoiceToStore = (payload) => {
  return { type: types.ADD_INVOICE_TO_STORE, payload: payload };
};

export const showInvoiceToStore = (payload) => {
  return { type: types.SHOW_INVOICE_TO_STORE, payload: payload };
};

export const addCommissionToStore = (payload) => {
  return { type: types.ADD_COMMISSION_TO_STORE, payload: payload };
};

export const addPaymentToStore = (payload) => {
  return { type: types.ADD_PAYMENT_TO_STORE, payload: payload };
};

export const addOrModifyEmployeeToStore = (payload) => {
  return { type: types.ADD_OR_MODIFY_EMPLOYEE_TO_STORE, payload: payload };
};

export const addOrModifyAgencyToStore = (payload) => {
  return { type: types.ADD_OR_MODIFY_AGENCY_TO_STORE, payload: payload };
};

export const addemployerToStore = (payload) => {
  return { type: types.ADD_EMPLOYER_TO_STORE, payload: payload };
};

export const updateEmployerToStore = (payload) => {
  return { type: types.MODIFY_EMPLOYER_TO_STORE, payload: payload };
};

export const invoiceAdded = () => {
  return { type: types.NEW_INVOICE_ADDED };
};

export const commissionAdded = () => {
  return { type: types.NEW_COMMISSION_ADDED };
};

export const employeeAddedOrUpdated = () => {
  return { type: types.NEW_EMPLOYEE_ADDED_OR_UPDATED };
};

export const agencyAddedOrUpdated = () => {
  return { type: types.NEW_AGENCY_ADDED_OR_UPDATED };
};

export const employerAdded = () => {
  return { type: types.NEW_EMPLOYER_ADDED };
};

export const employerUpdated = () => {
  return { type: types.EMPLOYER_UPDATED };
};

export const setBanksList = (payload) => {
  return { type: types.SET_BANKS_LIST, payload: payload };
};

export const setAutoInvoicing = (payload) => {
  return { type: types.SET_AUTO_INVOICING, payload: payload };
};

export const showCommissionToStore = (payload) => {
  return { type: types.SHOW_COMMISSION_TO_STORE, payload: payload };
}