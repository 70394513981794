import types from "../actions/types/types";

export const userDataReducer = (
  state = {
    token: "",
    user_data: { user_group_data: { phoneNumber: "", type: "" }, groups: [] },
  },
  action
) => {
  switch (action.type) {
    case types.USER.SET_DATA:
      return action.payload;
    case types.USER.SET_USER_PROFILE_PICTURE:
      return {
        ...state,
        user_data: {
          ...state.user_data,
          user_group_data: {
            ...state.user_data.user_group_data,
            profilePicture: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
