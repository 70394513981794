import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore } from "redux";
import { Provider } from "react-redux";
import allReducers from "./reducers";
import "./styles/custom.scss";
import "./styles/fonts.css";
import "./styles/default.css";
import "./styles/dataTables.bootstrap4.min.css";
import "./styles/menu.css";
import "@fortawesome/fontawesome-free/css/all.css";

const store =
  process.env.NODE_ENV != "production"
    ? createStore(
        allReducers,
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : createStore(allReducers);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
