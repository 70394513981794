import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { Image, Modal } from "react-bootstrap";
import avatar from "../../../images/avatar.jpg";
import ApiCalls from "../../../api/apiCalls";
import { toast } from "react-toastify";
import { setUserProfilePicture, showPopup } from "../../../actions";
import { ToastContainer } from "react-toastify";
import EditUsers from "../../../utils/popups/EditUsers";

const Profile = () => {
  const dispatch = useDispatch();
  const authToken = useSelector(({ userData: { token } }) => token);
  const user_group_data = useSelector(
    (state) => state.userData.user_data.user_group_data
  );

  const user_data = useSelector((state) => state.userData.user_data);

  const [profilePicture, setProfilePicture] = useState("");
  const [imageStatus, setImageStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [editType, setEditType] = useState("Edit Profile");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  let api = new ApiCalls();

  const sDate = `${user_data.date_joined}`.substr(0, 10);
  const lastLogin = `${user_data.last_login}`.substr(0, 10);

  useEffect(() => {
    setStartDate(sDate);

    // setImageStatus(
    //   profilePicture != avatar ? "Change Image" : `${imageStatus}`
    // );
  }, [sDate]);

  useEffect(() => {
    setProfilePicture(
      user_group_data.profilePicture != null
        ? `${api.SERVER}${user_group_data.profilePicture}`
        : ""
    );
  }, [user_group_data]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);

    let file = event.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
    if (file && ALLOWED_TYPES.includes(file.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(`${reader.result}`);
        setEditType("Update Profile");
      };
      reader.readAsDataURL(file);
    } else {
      console.log("something Went Wrong!");
    }
  };

  const uploadProfilePicture = (event) => {
    event.preventDefault();
    event.stopPropagation();

    let formData = new FormData();
    formData.append("username", user_data.username);
    if (selectedFile != null) {
      formData.append("profilePicture", selectedFile);
    }

    console.log(selectedFile);

    // try {
    //   let res = await api.uploadProfilePicture(formData, authToken);
    //   if (res.status === 200) {
    //     console.log('created');
    //   } else {
    //     console.log(res.message);
    //   }
    // }catch(e) {
    //   console.log(e.message);
    // }

    toast.promise(api.uploadProfilePicture(formData, authToken), {
      pending: "Please wait...",
      success: {
        render({ data }) {
          if (data.status === 200) {
            console.log(data);
            let responseBody = data.data;
            console.log(responseBody);
            dispatch(
              setUserProfilePicture(
                `/media/${responseBody.data.profilePicture}`
              )
            );
            return `${responseBody.message}`;
          }
        },
      },
      error: {
        render({ data }) {
          console.log("status code " + data.status);
          if (data.response) {
            console.log("status code " + data.response.status);
            let { message } = data.response.data;
            console.log("errorMessage:", message);
            return `${message}`;
          } else {
            console.log("An error occurred, try again later");
            return "An error occurred, try again later";
          }
        },
      },
    });
  };

  const showUpdateUserPopup = (id) => {
    dispatch(showPopup("edit_user", true));
  };

  const handleUpdateProfile = (e) => {
    if (editType === "Edit Profile") {
      // Open popup with name, profile Pics, and phone Number Edit oprions
      showUpdateUserPopup(user_data.id);
    } else if (editType === "Update Profile") {
      return uploadProfilePicture(e);
    }
  };

  const options = {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return (
    <div className="profile-section">
      <div className="profile-top div-employees-page-title">
        <span
          className="employees-page-title text-indigo-500"
          style={{
            fontWeight: "lighter",
            wordSpacing: "4px",
            letterSpacing: "1px",
            lineHeight: 1.8,
          }}
        >
          User Profile
        </span>
      </div>
      <div className="profile-bottom px-4">
        <div className="p-4 card d-flex flex-lg-row flex-column">
          <div className="profile-image-left p-2">
            <div className="ps-profile-picture-container d-flex justify-content-center">
              <div className="ps-profile-picture">
                <input
                  type="file"
                  onChange={handleFileChange}
                  name="profileImage"
                  id="photoFile"
                  className="d-none"
                />
                <label
                  className="profile-button w-100 h-100"
                  role="button"
                  htmlFor="photoFile"
                >
                  <i className="profile-icon fas fa-pen" />
                  <Image
                    src={profilePicture != "" ? profilePicture : avatar}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                    }}
                    alt="Loading..."
                  />
                  <div className="profile-image">
                    {" "}
                    <span> {imageStatus} </span>
                  </div>
                </label>
              </div>
            </div>

            <div className="profile-name text-center fs-3 mt-3">
              <span className="text-blueGray-400 uppercase font-bold">
                {user_group_data.name
                  ? user_group_data.name
                  : user_group_data.first_name +
                    " " +
                    user_group_data.last_name}
              </span>
            </div>
            {/* show only when Edit Profile button is pressed */}
            {/* <div className="profile-name-input">
              <input
                type="text"
                required
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                // onChange={handleChangeName}
                value={name}
              />
            </div> */}
            <div className="profile-update-button mt-3  mx-0 text-center">
              <button
                className="px-5 py-2 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded text-white bg-indigo-600 m-0"
                type="submit"
                // disabled={isSubmitButtonDisabled}
                onClick={handleUpdateProfile}
              >
                <span>{editType}</span>
              </button>
            </div>
          </div>
          <div className="profile-data-right px-2 py-3 flex-1">
            <div className="profile-1 d-flex flex-sm-row justify-content-between flex-column">
              <div className="py-3 profile-phoneNumber  d-flex flex-column text-center px-3 flex-1 me-sm-3 card mt-3 mt-sm-0">
                <i className="fa fa-phone py-2"></i>
                <span className="label text-blueGray-400 uppercase font-semibold  text-sm py-2">
                  Phone Number:{" "}
                </span>
                <span className="font-bold text-xl text-blueGray-700">
                  {user_group_data.phoneNumber}
                </span>
              </div>
              <div className="py-3 profile-userGroup d-flex flex-column text-center card px-3 flex-1 mt-3 mt-sm-0">
                <i className="fas fa-users py-2"></i>
                <span className="label text-blueGray-400 uppercase font-semibold  text-sm py-2">
                  User Group:{" "}
                </span>
                <span className="font-bold text-xl text-blueGray-700">
                  {user_group_data.type.toLocaleUpperCase()}
                </span>
              </div>
            </div>
            <div className="profile-1 d-flex flex-sm-row justify-content-between mt-sm-3 flex-column">
              <div className="py-3 profile-lastLogin d-flex flex-column text-center px-3 flex-1 me-sm-3 card mt-3 mt-sm-0">
                <i className="fas fa-clock py-2"></i>
                <span className="label text-blueGray-400 uppercase font-semibold  text-sm py-2">
                  Last LogIn:{" "}
                </span>
                <span className="font-bold text-xl text-blueGray-700">
                  {lastLogin}
                </span>
              </div>
              <div className="py-3 profile-dateJoined d-flex flex-column text-center px-3 flex-1 card mt-3 mt-sm-0">
                <i className="fas fa-calendar-plus py-2"></i>
                <span className="label text-blueGray-400 uppercase font-semibold  text-sm py-2">
                  Date Joined:{" "}
                </span>
                <span className="font-bold text-xl text-blueGray-700">
                  {startDate}
                </span>
              </div>
            </div>
            <div className="profile-1 d-flex flex-sm-row justify-content-between mt-3 flex-column">
              <div className="py-3 profile-email d-flex flex-column text-center card px-3 flex-1">
                <i className="fas fa-envelope-square py-2"></i>
                <span className="label text-blueGray-400 uppercase font-semibold  text-sm py-2">
                  Email:{" "}
                </span>
                <span className="font-bold text-xl text-blueGray-700">
                  {user_data.email}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditUsers />
      <ToastContainer />
    </div>
  );
};

export default Profile;
