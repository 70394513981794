import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FooterPagePro from "./footer/footer";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../images/cheque_handler_icon_1.png";

const PaymentResults = () => {
  // get url query parameters // key-value pairs after ?
  // retrieve the query part of the URL using location.search
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  const our_transaction_reference = queryParams.get("tx_ref");
  const flutterwave_transaction_id = queryParams.get("transaction_id");

  /*
  useEffect(() => {
    // TODO: check on our backend if {our_transaction_reference} is valid
  }, []);
  */

  return (
    <div style={{ minHeight: 500 }}>
      <div
        className="bg-indigo-500"
        style={{
          height: 60,
          color: "white",
          alignItems: "center",
          display: "flex",
          paddingLeft: 20,
        }}
      >
        <img src={logo} width={50}></img>
        <span style={{ fontSize: 19 }}>ChequeHandler</span>
      </div>
      <Container
        style={{
          minHeight: 300,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {status === "successful" ? (
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Col
              lg={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-500"
                />
              </h1>
            </Col>
            <Col
              lg={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                className="payment-results-text"
                style={{ textAlign: "center" }}
              >
                Payment with reference {our_transaction_reference} was
                successfully processed. Thank you for using our services.
              </span>
            </Col>
          </Row>
        ) : (
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Col
              lg={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>
                <FontAwesomeIcon icon={faTimesCircle} color="#D22B2B" />
              </h1>
            </Col>
            <Col
              lg={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                className="payment-results-text"
                style={{ textAlign: "center" }}
              >
                Transaction failed with reference {our_transaction_reference}.
                Please try again later.
              </span>
            </Col>
          </Row>
        )}
      </Container>
      <FooterPagePro />
    </div>
  );
};

export default PaymentResults;
