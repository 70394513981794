import React, { useState, useEffect } from "react";
import { MDBContainer, MDBDataTable } from "mdbreact";
import { Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { createInvoice, showPopup } from "../../actions";

const Status = ({ isActive }) => {
  let badge = isActive ? "bg-green-500" : "bg-red-300";
  return (
    <span
      style={{
        color: "white",
      }}
      className={`badge badge ${badge} table-tbody`}
    >
      {isActive ? "active" : "stopped"}
    </span>
  );
};

const Label = ({ label }) => {
  return (
    <span style={{ cursor: "pointer" }}>
      {label}
      <span
        class="text-blueGray-500 "
        style={{
          marginLeft: 4,
        }}
      >
        ↑↓
      </span>
    </span>
  );
};

function Employees({ history }) {
  const [employeesDataTable, setEmployeesDataTable] = useState({});
  const employees = useSelector(({ records: { employees } }) => employees);
  const employers = useSelector(({ records: { employers } }) => employers);
  const showSelectEmployeePopup = useSelector(
    ({ showSelectEmployeePopup }) => showSelectEmployeePopup
  );
  const invoices_currency = useSelector(
    ({ records: { invoices_currency } }) => invoices_currency
  );
  const [scrollY, setScrollY] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const displayData = employees.reverse().map((employee) => {
      let employer = employers.find(
        (employer) => employer.id === employee.employer
      );
      return {
        name: employee.first_name + " " + employee.last_name,
        phoneNumber: employee.phoneNumber,
        employer: employer != undefined ? employer.name : "",
        salary:
          employee.monthlySalary != null
            ? `${
                invoices_currency == "USD" ? "$" : invoices_currency
              } ${Number.parseInt(employee.monthlySalary).toLocaleString()}`
            : "",
        isActive: <Status isActive={employee.isActive} />,
        isActiveSearchable: employee.isActive ? "Active" : "Stopped",
        bankName: employee.bankName,
        bankAccountNumber: employee.bankAccountNumber,
        clickEvent: () => {
          if (employee.isActive && employer != undefined) {
            dispatch(
              createInvoice({
                employer: {
                  name: employer.name,
                  email: employer.email,
                  id: employer.id,
                },
                employee: {
                  name: employee.first_name + " " + employee.last_name,
                  id: employee.id,
                  monthlySalary: employee.monthlySalary,
                },
                agency: {
                  id: employee.agency,
                },
              })
            );
            dispatch(showPopup("create_invoice", true));
            dispatch(showPopup("select_employee", false));
          } else {
            // show toast
          }
        },
      };
    });

    setEmployeesDataTable({
      columns: [
        {
          label: <Label label="Name" />,
          field: "name",
          sort: "asc",
          width: 100,
        },
        // {
        //   label: <Label label="Phone" />,
        //   field: "phoneNumber",
        //   sort: "asc",
        //   width: 100,
        // },
        {
          label: <Label label="Employer" />,
          field: "employer",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Salary" />,
          field: "salary",
          sort: "asc",
          width: 150,
        },
        {
          label: <Label label="Bank" />,
          field: "bankName",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Account" />,
          field: "bankAccountNumber",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Status" />,
          field: "isActive",
          sort: "asc",
          width: 100,
        },
      ],
      rows: displayData,
    });
  }, [employees]);

  const handleClose = () => {
    dispatch(showPopup("select_employee", false));
  };

  useEffect(() => {
    if (window.innerWidth >= 775) {
      setScrollY(false);
    } else {
      setScrollY(true);
    }
    const handleResize = (event) => {
      var width = window.innerWidth;
      if (width >= 775) {
        setScrollY(false);
      } else {
        setScrollY(true);
      }
    };
    window.addEventListener("resize", handleResize);

    // cleanup this component // when component unmounts // run this callback
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // runs only once // empty dependency list

  return (
    <Modal
      show={showSelectEmployeePopup}
      onHide={handleClose}
      centered
      backdrop={false}
      size="xl"
    >
      <Modal.Header
        closeButton
        style={{
          backgroundColor: "#eee",
          fontSize: 40,
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}
      >
        <Modal.Title>Select Employee</Modal.Title>
      </Modal.Header>
      <>
        <div className="bg-blueGray-100 padding-data-table py-2">
          <MDBContainer
            className="shadow-sm"
            style={{
              backgroundColor: "white",
              paddingTop: 10,
              borderRadius: 10,
              paddingBottom: 10,
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            <MDBDataTable
              borderless
              maxHeight="100vh"
              hover
              //scrollY={scrollY}
              //striped
              //bordered
              //small
              responsive
              responsiveSm
              responsiveMd
              responsiveLg
              responsiveXl
              theadColor="#5072a7"
              data={employeesDataTable}
              id="tableID"
              exportToCSV
              noBottomColumns
              pagesAmount={2}
            />
          </MDBContainer>
        </div>
      </>
    </Modal>
  );
}

export default withRouter(Employees);
