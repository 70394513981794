import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import ApiCalls from "../../api/apiCalls";
import { setBanksList, setRecords, setUserData } from "../../actions";
import Loader from "../Loader";


// get all props of <PrivateRoute/>
export const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const storedToken = localStorage.getItem(
    `${process.env.REACT_APP_ACCESS_TOKEN_KEY}`
  );
  const [isLoading, setIsLoading] = useState(true);
  let api = new ApiCalls();
  const dispatch = useDispatch();

  useEffect(async () => {
    if (storedToken != null) {
      try {
        let response = await api.getCurrentUser(storedToken);
        if (response.status === 200) {
          setIsLoading(false);
          let responseBody = response.data;
          console.log(responseBody.message);
          // console.log(responseBody.data);
          let user_data = responseBody.data;
          dispatch(setUserData({ ...user_data, token: storedToken })); // update redux store
        }
      } catch (error) {
        if (error.response) {
          console.log("status code " + error.response.status);
          let { message } = error.response.data;
          console.log("errorMessage:", message);
          // alert(message);
        } else {
          console.log("An error occurred. Please try again later");
        }
      }
    }
  }, [storedToken]);

  useEffect(async () => {
    if (storedToken != null) {
      try {
        let response = await api.getAllData(storedToken);
        if (response.status === 200) {
          setIsLoading(false);
          let responseBody = response.data;
          console.log(responseBody.message);
          // console.log(responseBody.data);
          dispatch(setRecords(responseBody.data));
        }
      } catch (error) {
        if (error.response) {
          console.log("status code " + error.response.status);
          let { message } = error.response.data;
          console.log("errorMessage:", message);
          // alert(message);
        } else {
          console.log("An error occurred. Please try again later");
        }
      }
    }
  }, [storedToken]);

  useEffect(async () => {
    if (storedToken != null) {
      try {
        let response = await api.getAllBanks("UG"); // UG --> Uganda | KE --> Kenya
        if (response.status === 200) {
          let responseBody = response.data;
          console.log(responseBody.message);
          // console.log(responseBody.data);
          dispatch(setBanksList(responseBody.data));
        }
      } catch (error) {
        if (error.response) {
          console.log("status code " + error.response.status);
          let { message } = error.response.data;
          console.log("errorMessage:", message);
          // alert(message);
        } else {
          console.log("An error occurred. Please try again later");
        }
      }
    }
  }, [storedToken]);

  return (
    isLoading ? <Loader /> : (
    <Route
      {...rest}
      render={(routeProps) => {
        if (storedToken != null) {
          return <RouteComponent {...routeProps} />;
        } else {
          return <Redirect to={"/"} />; // to home
        }
      }}
    />
    )
  );
};
