import { MDBContainer, MDBDataTable } from "mdbreact";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { showInvoiceToStore, showPopup } from "../../../actions";
import CreateInvoice from "../../../utils/popups/createInvoice";
import Employees from "../../../utils/popups/employees";
import { ToastContainer } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import Invoice from "./Invoice";

const Status = ({ status }) => {
  const [showTextStatus, setShowTextStatus] = useState(false);
  let badge =
    status === "sent"
      ? "blue-500"
      : status === "paid"
      ? "green-500"
      : status === "transfering"
      ? "yellow-500"
      : status === "transfer failed" || status === "payment failed"
      ? "red-500"
      : status === "withdrawn"
      ? "purple-500"
      : "blue-500";

  useEffect(() => {
    if (window.innerWidth >= 775) {
      setShowTextStatus(false);
    } else {
      setShowTextStatus(true);
    }
    const handleResize = (event) => {
      //var width = window.innerWidth;
      if (window.innerWidth >= 775) {
        setShowTextStatus(false);
      } else {
        setShowTextStatus(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Row
      style={{
        color: "black",
        cursor: "pointer",
      }}
      className={`table-tbody`}
    >
      <Col
        md={2}
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "cursive",
          paddingTop: 5,
        }}
      >
        <div
          style={{
            width: 16,
            height: 16,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // boxShadow: "2px 2px",
          }}
          className={`bg-${badge} text-${badge}`}
          title={status.toUpperCase()}
        >
          oo
        </div>
      </Col>
      <Col hidden={showTextStatus}>{status.toUpperCase()}</Col>
    </Row>
  );
};

const Label = ({ label }) => {
  return (
    <span style={{ cursor: "pointer" }}>
      {label}
      <span
        class="text-blueGray-500 "
        style={{
          marginLeft: 4,
        }}
      >
        ↑↓
      </span>
    </span>
  );
};

function Invoices({ history }) {
  const [invoicesDataTable, setInvoicesDataTable] = useState({});
  const user_data = useSelector((state) => state.userData.user_data);
  const invoices = useSelector(({ records: { invoices } }) => invoices);
  const item = useSelector((state) => state.showInvoiceData);
  const employers = useSelector(({ records: { employers } }) => employers); // has employer name,..
  const payments = useSelector(({ records: { payments } }) => payments); // has transactionId,...
  const employees = useSelector(({ records: { employees } }) => employees);
  const invoicesAdded = useSelector(({ invoicesAdded }) => invoicesAdded);
  const invoices_currency = useSelector(
    ({ records: { invoices_currency } }) => invoices_currency
  );
  const [scrollY, setScrollY] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    // invoices.reverse() --> to start with the newest invoices
    const displayData = invoices.reverse().map((invoice) => {
      let id = invoice.id;
      let employer = employers.find(
        (employer) => employer.id === invoice.employer
      );
      let employee = employees.find(
        (employee) => employee.id === invoice.employee
      );
      let payment = payments.find((payment) => payment.id === invoice.payment);
      return {
        amount: `${
          invoices_currency === "USD" ? "$" : invoices_currency
        } ${Number.parseInt(payment.amount).toLocaleString()}`,
        transactionFee:
          payment.transactionFee != null
            ? Number.parseInt(payment.transactionFee).toLocaleString()
            : " ",
        status: <Status status={invoice.status} />,
        statusSearchable: invoice.status,
        createdAt: new Date(invoice.createdAt).toLocaleString("en-UG", options),
        employer:
          employer != undefined ? (
            employer.name
          ) : (
            <span style={{ fontSize: "1.3rem" }}>
              &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;-
            </span>
          ),
        employee: employee.first_name + " " + employee.last_name,
        payment: payment.transactionId.toLowerCase(),
        clickEvent: () => {
          // dispatch(showPopup("show_invoice", true));
          dispatch(
            showInvoiceToStore({
              inv: {
                id: id,
                amount: `${
                  invoices_currency === "USD" ? "$" : invoices_currency
                } ${Number.parseInt(payment.amount).toLocaleString()}`,
                status: invoice.status,
                createdAt: new Date(invoice.createdAt).toLocaleString("en-UG"),
              },
              payment: {
                id: payment.id,
                amount: payment.amount,
                transactionFee: payment.transactionFee,
                transactionId: payment.transactionId,
                status: invoice.status,
                comment: payment.comment,
                rate: invoice.rate,
                transfered_rate: invoice.transfered_rate,
              },
              employer: {
                name: employer != undefined ? employer.name : null,
                email: employer != undefined ? employer.email : null,
                id: employer != undefined ? employer.id : null,
              },
              employee: {
                name: employee.first_name + " " + employee.last_name,
                id: employee.id,
                monthlySalary: employee.monthlySalary,
              },
              agency: {
                id: null,
              },
            })
          ); // add it to redux store
          history.push(`/dashboard/invoice/${invoice.id}`);
        },
      };
    });

    setInvoicesDataTable({
      columns: [
        {
          label: <Label label="Employer" />,
          field: "employer",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label={"Amount"} />,
          field: "amount",
          sort: "asc",
          width: 180,
        },
        {
          label: <Label label="Reference" />,
          field: "payment",
          sort: "asc",
          width: 190,
        },
        {
          label: <Label label="Status" />,
          field: "status",
          sort: "asc",
          width: 150,
        },
        {
          label: <Label label="Date" />,
          field: "createdAt",
          sort: "asc",
          width: 270,
        },
      ],
      rows: displayData,
    });
  }, [invoices, invoicesAdded, item]);

  useEffect(() => {
    if (window.innerWidth >= 775) {
      setScrollY(false);
    } else {
      setScrollY(true);
    }
    const handleResize = (event) => {
      var width = window.innerWidth;
      if (width >= 775) {
        setScrollY(false);
      } else {
        setScrollY(true);
      }
    };
    window.addEventListener("resize", handleResize);

    // cleanup this component // when component unmounts // run this callback
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // runs only once // empty dependency list

  return (
    <>
      <div>
        <div className="div-employees-page-title">
          <span
            className="employees-page-title text-indigo-500"
            style={{
              fontWeight: "lighter",
              wordSpacing: "4px",
              letterSpacing: "1px",
              lineHeight: 1.8,
            }}
          >
            Invoices
          </span>
          <button
            //hidden={user_data.groups[0] != 3} // only supersite
            style={{
              fontWeight: "lighter",
              wordSpacing: "1px",
              lineHeight: 1,
              marginLeft: "8vw",
            }}
            class="bg-indigo-500 hover:bg-blueGray-500 text-white font-bold py-2 px-3 rounded-full"
            onClick={() => {
              dispatch(showPopup("select_employee", true));
            }}
          >
            <i class="fa fa-plus" aria-hidden="true" />
            New Invoice
          </button>
        </div>
        <div className="padding-data-table padding-for-bottom-navigation">
          <MDBContainer
            className="shadow-sm"
            style={{
              backgroundColor: "white",
              paddingTop: 10,
              borderRadius: 10,
              paddingBottom: 10,
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            <MDBDataTable
              borderless
              maxHeight="100vh"
              hover
              responsive
              responsiveSm
              responsiveMd
              responsiveLg
              responsiveXl
              theadColor="#5072a7"
              data={invoicesDataTable}
              id="tableID"
              exportToCSV
              noBottomColumns
              entriesOptions={[20, 50, 100]}
              //paging={false}
              pagesAmount={3}
            />
          </MDBContainer>
        </div>
        {/* <div className="create-invoice-btn">
          <div
            className="bg-indigo-900 shadow-lg"
            style={{
              position: "fixed",
              padding: 15,
              zIndex: 3,
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(showPopup("select_employee", true));
            }}
          >
            Create invoice
          </div>
        </div> */}
      </div>
      {/* popup for Employees */}
      <Employees />
      {/* popup for create invoice */}
      <CreateInvoice />
      <ToastContainer />
    </>
  );
}

export default Invoices;
