import React, { useState, useEffect } from "react";
import { MDBContainer, MDBDataTable } from "mdbreact";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createOrUpdateAgency, showPopup } from "../../../actions";
import EditAgency from "./../../../utils/popups/editAgency";

const Label = ({ label }) => {
  return (
    <span style={{ cursor: "pointer" }}>
      {label}
      <span
        class="text-blueGray-500 "
        style={{
          marginLeft: 4,
        }}
      >
        ↑↓
      </span>
    </span>
  );
};

const Agencies = ({ history }) => {
  const [agenciesDataTable, setAgenciesDataTable] = useState({});
  const agencies = useSelector(({ records: { agencies } }) => agencies);
  const [scrollY, setScrollY] = useState(false);
  const agencyAddedOrUpdated = useSelector(
    ({ agencyAddedOrUpdated }) => agencyAddedOrUpdated
  );
  const dispatch = useDispatch();

  const editAgency = (id) => {
    dispatch(
      createOrUpdateAgency({
        agencyId: id,
      })
    );
    dispatch(showPopup("edit_agency", true));
  };

  useEffect(() => {
    const displayData = agencies.reverse().map((agency) => {
      return {
        name: agency.name,
        phoneNumber: agency.phoneNumber,
        address: agency.address,
        bankName: agency.bankName,
        bankAccountNumber: agency.bankAccountNumber,
        edit: (
          <button
            class="hover:bg-indigo-500 text-indigo-500 font-semibold hover:text-white py-1 px-3 border border-indigo-500 hover:border-transparent rounded"
            onClick={() => {
              editAgency(agency.id);
            }}
          >
            Edit
          </button>
        ),
      };
    });

    setAgenciesDataTable({
      columns: [
        {
          label: <Label label="Name" />,
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: <Label label="Phone" />,
          field: "phoneNumber",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Address" />,
          field: "address",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Bank" />,
          field: "bankName",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Account" />,
          field: "bankAccountNumber",
          sort: "asc",
          width: 100,
        },
        {
          // label: <Label label="Edit" />,
          label: "Edit",
          field: "edit",
          sort: "asc",
          width: 100,
        },
      ],
      rows: displayData,
    });
  }, [agencies, agencyAddedOrUpdated]);

  useEffect(() => {
    if (window.innerWidth >= 775) {
      setScrollY(false);
    } else {
      setScrollY(true);
    }
    const handleResize = (event) => {
      var width = window.innerWidth;
      if (width >= 775) {
        setScrollY(false);
      } else {
        setScrollY(true);
      }
    };
    window.addEventListener("resize", handleResize);

    // cleanup this component // when component unmounts // run this callback
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // runs only once // empty dependency list

  return (
    <>
      <div>
        <div className="div-employees-page-title">
          <span
            className="employees-page-title text-indigo-500"
            style={{
              fontWeight: "lighter",
              wordSpacing: "4px",
              letterSpacing: "1px",
              lineHeight: 1.8,
            }}
          >
            Agencies
          </span>
          <button
            style={{
              fontWeight: "lighter",
              wordSpacing: "1px",
              lineHeight: 1,
              marginLeft: "8vw",
            }}
            class="bg-indigo-500 hover:bg-blueGray-500 text-white font-bold py-2 px-3 rounded-full"
            onClick={() => {
              editAgency(null);
            }}
          >
            <i class="fa fa-plus" aria-hidden="true" />
            New Agency
          </button>
        </div>
        <div className="padding-data-table padding-for-bottom-navigation">
          <MDBContainer
            className="shadow-sm"
            style={{
              backgroundColor: "white",
              paddingTop: 10,
              borderRadius: 10,
              paddingBottom: 10,
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            <MDBDataTable
              borderless
              maxHeight="100vh"
              hover
              responsive
              responsiveSm
              responsiveMd
              responsiveLg
              responsiveXl
              theadColor="#5072a7"
              data={agenciesDataTable}
              id="tableID"
              exportToCSV
              noBottomColumns
              pagesAmount={3}
              entriesOptions={[20, 50, 100]}
            />
          </MDBContainer>
        </div>
      </div>
      <EditAgency />
      <ToastContainer />
    </>
  );
};

export default Agencies;
