import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import ApiCalls from "../../../api/apiCalls";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const Invoice = () => {
  const item = useSelector((state) => state.showInvoiceData);
  const { id } = useParams();
  const authToken = useSelector(({ userData: { token } }) => token);
  let api = new ApiCalls();
  const invoices_currency = useSelector(
    ({ records: { invoices_currency } }) => invoices_currency
  );

  let date = `${item.inv.createdAt}`.substr(0, 10);
  let tranFee = `${
    item.payment.transactionFee != null ? item.payment.transactionFee : 0
  }`;
  let total = `${invoices_currency === "USD" ? "$" : invoices_currency} ${(
    parseInt(item.payment.amount) + parseInt(tranFee)
  ).toLocaleString()}`;

  console.log(item);

  const Status = ({ status }) => {
    const [showTextStatus, setShowTextStatus] = useState(false);
    let badge =
      status === "sent"
        ? "blue-500"
        : status === "paid"
        ? "green-500"
        : status === "transfering"
        ? "yellow-500"
        : status === "transfer failed" || status === "payment failed"
        ? "red-500"
        : status === "withdrawn"
        ? "purple-500"
        : "blue-500";

    useEffect(() => {
      if (window.innerWidth >= 775) {
        setShowTextStatus(false);
      } else {
        setShowTextStatus(true);
      }
      const handleResize = (event) => {
        //var width = window.innerWidth;
        if (window.innerWidth >= 775) {
          setShowTextStatus(false);
        } else {
          setShowTextStatus(true);
        }
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
      <div
        style={{
          color: "black",
          cursor: "pointer",
        }}
        className={`table-tbody d-flex justify-content-md-end  `}
      >
        <div
          // md={2}
          style={{
            display: "flex",
            alignItems: "center",
            fontFamily: "cursive",
            paddingTop: 5,
          }}
        >
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // boxShadow: "2px 2px",
            }}
            className={`bg-${badge} text-${badge} mr-5`}
            title={status}
          >
            o
          </div>
        </div>
        <div>{status.toUpperCase()}</div>
      </div>
    );
  };

  const resendInvoice = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (item.inv.status === "paid") {
      toast("This invoice is already paid", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "warning",
      });
      return;
    }

    if (item.employer.id != null) {
      // try {
      //   let res = await api.reSendInvoice(id, authToken);
      //   console.log(res);
      // } catch {
      //   console.log(e.message);
      // } test using try catch to check the error

      toast.promise(api.reSendInvoice(id, authToken), {
        pending: "Please wait...",
        success: {
          render({ data }) {
            if (data.status === 200) {
              let responseBody = data.data;
              console.log(responseBody.message);
              return `${responseBody.message}`;
            }
          },
        },
        error: {
          render({ data }) {
            if (data.response) {
              console.log("status code " + data.response.status);
              let { message } = data.response.data;
              console.log("errorMessage:", message);
              return `${message}`;
            } else {
              console.log("An error occurred, try again later");
              return "An error occurred, try again later";
            }
          },
        },
      });
    } else {
      toast("No employer is attached to this invoice", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "warning",
      });
    }
  };

  return (
    <div className="invoice pt-1 pb-5">
      <div className="invoice-top px-4 py-2">
        <div className="invoice-topCenter div-employees-page-title">
          <span
            className="invoice-page-title text-indigo-500 fw-bold"
            // style={{
            //   fontWeight: "lighter",
            //   wordSpacing: "4px",
            //   letterSpacing: "1px",
            //   lineHeight: 1.8,
            // }}
          >
            Invoice Details
          </span>
          <button
            // style={{
            //   fontWeight: "lighter",
            //   wordSpacing: "1px",
            //   lineHeight: 1,
            //   marginLeft: "8vw",
            // }}
            class="resend-invoice bg-indigo-500 hover:bg-blueGray-500 text-white font-bold py-2 px-3 rounded-full"
            onClick={resendInvoice}
          >
            {/* <i class="fa fa-plus" aria-hidden="true" /> */}
            Resend Invoice
          </button>
        </div>
        {/* <div className="invoice-topCenter">
          <p className="text-uppercase align-items-center fs-3 fw-bold p-3 text-center"> Invoice Details </p>
          <button
            style={{
              fontWeight: "lighter",
              wordSpacing: "1px",
              lineHeight: 1,
              marginLeft: "8vw",
            }}
            class="bg-indigo-500 hover:bg-blueGray-500 text-white font-bold py-2 px-3 rounded-full"
            onClick={() => {
              // dispatch(showPopup("select_employee", true));
            }}
          >
            <i class="fa fa-plus" aria-hidden="true" />
            Resend Invoice
          </button>
        </div> */}
        <div className="invoice-topLine d-flex flex-md-row flex-column-reverse justify-content-between align-items-center text-center pt-4">
          <div className="invoice-topLeft text-start col-12 col-md-7 col-lg-8">
            <h3 className="fw-bold">{item.inv.amount}</h3>
          </div>

          <div className="invoice-topright d-flex flex-row flex-md-column justify-content-between text-md-end col-12 col-md-5 col-lg-4">
            <p> {date} </p>
            <div className="fw-bold">
              {" "}
              <Status status={item.inv.status} />{" "}
            </div>
          </div>
        </div>
        <div className="invoice-bottomLine">
          <p> Some Information about this Invoice </p>
        </div>
      </div>

      <div className="invoice-middle">
        <div className="card bg-light d-flex flex-lg-row py-3 mx-4 justify-content-between mb-5 mb-md-0">
          <div className="card-left col-lg-6">
            <div className="card-detail1 mx-4 d-flex flex-row flex-2">
              <p className="card-tittle w-50">Transaction Reference: </p> &nbsp;
              &nbsp;
              <p className="card-data fst-italic">
                {" "}
                {item.payment.transactionId.toLowerCase()}{" "}
              </p>
            </div>
            <div className="card-detail1 mx-4 d-flex flex-row flex-1">
              <p className="card-tittle font-weight-bold w-50">Employee: </p>{" "}
              &nbsp; &nbsp;
              <p className="fst-italic"> {item.employee.name} </p>
            </div>
            <div className="card-detail1 mx-4 d-flex flex-row flex-1">
              <p className="card-tittle font-weight-bold w-50">Employer: </p>{" "}
              &nbsp; &nbsp;
              <p className="fst-italic">
                {" "}
                {item.employer.name != null ? (
                  item.employer.name
                ) : (
                  <span style={{ fontSize: "1.3rem" }}>
                    &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;-
                  </span>
                )}{" "}
              </p>
            </div>
            <div className="card-detail1 mx-4  d-flex flex-row flex-1">
              <p className="card-tittle w-50">Comment: </p> &nbsp; &nbsp;
              <p className="fst-italic">
                {" "}
                {item.payment.comment === null
                  ? "No Comments"
                  : item.payment.comment}{" "}
              </p>
            </div>
            <div
              className="card-detail1 mx-4  d-flex flex-row flex-1"
              hidden={item.inv.status != "paid"}
            >
              <p
                className="card-tittle w-50"
                hidden={item.inv.status != "paid"}
              >
                FX Rate:{" "}
              </p>{" "}
              &nbsp; &nbsp;
              <p className="fst-italic">
                {" "}
                {item.payment.transfered_rate === null
                  ? ""
                  : parseInt(
                      item.payment.transfered_rate
                    ).toLocaleString()}{" "}
              </p>
            </div>
          </div>
          <div className="card-right col-lg-6">
            <div className="card-detail1 mx-4  d-flex flex-row flex-1">
              <p className="card-tittle w-50">Currency: </p> &nbsp; &nbsp;
              <p className="fst-italic">{invoices_currency}</p>
            </div>
            <div className="card-detail1 mx-4 d-flex flex-row flex-1">
              <p className="card-tittle w-50">Amount: </p> &nbsp; &nbsp;
              <p className="fst-italic"> {item.inv.amount} </p>
            </div>
            <div className="card-detail1 mx-4 d-flex flex-row flex-1">
              <p className="card-tittle font-weight-bold w-50">
                Transaction Fee:{" "}
              </p>{" "}
              &nbsp; &nbsp;
              <p className="fst-italic">
                {`${
                  invoices_currency === "USD" ? "$" : invoices_currency
                } ${parseInt(item.payment.transactionFee).toLocaleString()}`}
              </p>
            </div>
            <div className="card-detail1 mx-4 d-flex flex-row flex-1">
              <p className="card-tittle font-weight-bold w-50">Total: </p>{" "}
              &nbsp; &nbsp;
              <p className="fst-italic"> {total} </p>
            </div>
            <div
              className="card-detail1 mx-4 d-flex flex-row flex-1"
              hidden={item.inv.status != "paid"}
            >
              <p
                className="card-tittle font-weight-bold w-50"
                hidden={item.inv.status != "paid"}
              >
                Payout:{" "}
              </p>{" "}
              &nbsp; &nbsp;
              <p className="fst-italic">
                {" "}
                {item.payment.transfered_rate != null
                  ? `UGX ${(
                      parseInt(item.payment.amount) *
                      parseInt(item.payment.transfered_rate)
                    ).toLocaleString()}`
                  : ""}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Invoice;
