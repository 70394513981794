import types from "../actions/types/types";

export const createInvoiceReducer = (
  state = {
    employer: {
      name: "",
      email: "",
      id: "",
    },
    employee: {
      name: "",
      id: "",
      monthlySalary: "",
    },
    agency: {
      id: null,
    },
  },
  action
) => {
  switch (action.type) {
    case types.CREATE_INVOICE:
      return action.payload;
    default:
      return state;
  }
};
