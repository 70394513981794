import types from "./types/types";

export const showPopup = (name, show) => {
  switch (name) {
    case "verify_otp":
      return { type: types.SHOW_POPUP.VERIFY_OTP, payload: show };
    case "create_invoice":
      return { type: types.SHOW_POPUP.CREATE_INVOICE, payload: show };
    case "create_commission":
      return { type: types.SHOW_POPUP.CREATE_COMMISSION, payload: show };
    case "select_employee":
      return { type: types.SHOW_POPUP.SELECT_EMPLOYEE, payload: show };
    case "select_agency":
      return { type: types.SHOW_POPUP.SELECT_AGENCY, payload: show };
    case "edit_employee":
      return { type: types.SHOW_POPUP.EDIT_EMPLOYEE, payload: show };
    case "edit_agency":
      return { type: types.SHOW_POPUP.EDIT_AGENCY, payload: show };
    case "edit_employer":
      return { type: types.SHOW_POPUP.EDIT_EMPLOYER, payload: show };
    case "show_invoice":
      return { type: types.SHOW_POPUP.SHOW_INVOICE, payload: show };
    case "edit_user":
      return { type: types.SHOW_POPUP.EDIT_USER, payload: show }; 
    default:
      break;
  }
};
