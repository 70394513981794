export default {
  USER: {
    SET_DATA: "SET_USER_DATA",
    CHANGE_DATA: "CHANGE_USER_DATA",
    SET_USER_PROFILE_PICTURE: "SET_USER_PROFILE_PICTURE",
  },
  SET_RECORDS: "SET_RECORDS",
  SHOW_POPUP: {
    VERIFY_OTP: "VERIFY_OTP",
    CREATE_INVOICE: "SHOW_CREATE_INVOICE",
    CREATE_COMMISSION: "SHOW_CREATE_COMMISSION",
    SELECT_EMPLOYEE: "SELECT_EMPLOYEE",
    SELECT_AGENCY: "SELECT_AGENCY",
    EDIT_EMPLOYEE: "EDIT_EMPLOYEE",
    EDIT_AGENCY: "EDIT_AGENCY",
    EDIT_EMPLOYER: "EDIT_EMPLOYER",
    SHOW_INVOICE: "SHOW_INVOICE",
    EDIT_USER: "EDIT_USER",
  },
  CREATE_INVOICE: "CREATE_INVOICE",
  CREATE_COMMISSION: "CREATE_COMMISSION",
  CREATE_OR_UPDATE_EMPLOYEE: "CREATE_OR_UPDATE_EMPLOYEE",
  CREATE_OR_UPDATE_AGENCY: "CREATE_OR_UPDATE_AGENCY",
  CREATE_OR_UPDATE_EMPLOYER: "CREATE_OR_UPDATE_EMPLOYER",
  ADD_INVOICE_TO_STORE: "ADD_INVOICE_TO_STORE",
  ADD_COMMISSION_TO_STORE: "ADD_COMMISSION_TO_STORE",
  ADD_PAYMENT_TO_STORE: "ADD_PAYMENT_TO_STORE",
  ADD_OR_MODIFY_EMPLOYEE_TO_STORE: "ADD_OR_MODIFY_EMPLOYEE_TO_STORE",
  ADD_OR_MODIFY_AGENCY_TO_STORE: "ADD_OR_MODIFY_AGENCY_TO_STORE",
  ADD_EMPLOYER_TO_STORE: "ADD_EMPLOYER_TO_STORE",
  MODIFY_EMPLOYER_TO_STORE: "MODIFY_EMPLOYER_TO_STORE",
  NEW_INVOICE_ADDED: "NEW_INVOICE_ADDED",
  NEW_COMMISSION_ADDED: "NEW_COMMISSION_ADDED",
  NEW_EMPLOYEE_ADDED_OR_UPDATED: "NEW_EMPLOYEE_ADDED_OR_UPDATED",
  NEW_AGENCY_ADDED_OR_UPDATED: "NEW_AGENCY_ADDED_OR_UPDATED",
  NEW_EMPLOYER_ADDED: "NEW_EMPLOYER_ADDED",
  EMPLOYER_UPDATED: "EMPLOYER_UPDATED",
  SET_BANKS_LIST: "SET_BANKS_LIST",
  SET_AUTO_INVOICING: "SET_AUTO_INVOICING",
  SHOW_COMMISSION_TO_STORE: "SHOW_COMMISSION_TO_STORE",
  SHOW_INVOICE_DATA: "SHOW_INVOICE_DATA",
  SHOW_INVOICE_TO_STORE: "SHOW_INVOICE_TO_STORE",
};
