import React, { useEffect, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { withRouter } from "react-router-dom";
import ApiCalls from "../../api/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { setBanksList, setUserData, showPopup } from "../../actions";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function CardSignUp({ history }) {
  // account type
  const [type, setType] = useState("agency");
  // form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [village, setVillage] = useState("");
  const [nin, setNIN] = useState(""); // NIN / Passport Number
  const [dob, setDOB] = useState(""); // date of birth
  const [bankCode, setBankCode] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [district, setDistrict] = useState("");
  const [parentGuardian, setParentGuardian] = useState("");
  const [lc1Name, setLc1Name] = useState("");
  const [bioData, setBioData] = useState("");
  const [gender, setGender] = useState("");
  // submit button
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const bankslist = useSelector(({ bankslist }) => bankslist);

  let api = new ApiCalls();
  const dispatch = useDispatch();

  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const handleChangeFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const handleChangeLastName = (event) => {
    setLastName(event.target.value);
  };
  const handleChangeAgencyName = (event) => {
    setAgencyName(event.target.value);
  };
  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleChangeAddress = (event) => {
    setAddress(event.target.value);
  };
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleChangeState = (event) => {
    setState(event.target.value);
  };
  const handleChangeVillage = (event) => {
    setVillage(event.target.value);
  };
  const handleChangeNIN = (event) => {
    setNIN(event.target.value);
  };
  const handleChangeDOB = (event) => {
    setDOB(event.target.value);
  };
  const handleChangeBankCode = (event, bank) => {
    // if an object is selected
    if (bank) {
      setBankCode(bank.code);
      setBankName(bank.name);
    } else {
      // object is unselected
      setBankCode(null);
      setBankName(null);
    }
  };
  const handleChangeBankAccountNumber = (event) => {
    setBankAccountNumber(event.target.value);
  };
  const handleChangeDistrict = (event) => {
    setDistrict(event.target.value);
  };
  const handleChangeParentGuardian = (event) => {
    setParentGuardian(event.target.value);
  };
  const handleChangeLC1Name = (event) => {
    setLc1Name(event.target.value);
  };
  const handleChangeBioData = (event) => {
    setBioData(event.target.value);
  };
  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const createUser = async (data) => {
    toast.promise(
      type === "employee" ? api.createEmployee(data) : api.createAgency(data),
      {
        pending: "Please wait...",
        success: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.status === 200) {
              let responseBody = data.data;
              console.log(responseBody.message);
              dispatch(setUserData(responseBody.data));
              dispatch(showPopup("verify_otp", true));
              return `${responseBody.message}`;
            }
          },
        },
        error: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.response) {
              console.log("status code " + data.response.status);
              let { message } = data.response.data;
              console.log("errorMessage:", message);
              return `${message}`;
            } else {
              console.log("An error occurred, try again later");
              return "An error occurred, try again later";
            }
          },
        },
      }
    );
  };

  const signup = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmitButtonDisabled(true);
    // TODO: verify validation
    if (phoneNumber.trim().replace(" ", "").length < 10) {
      toast("Phone number is invalid!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
      setIsSubmitButtonDisabled(false);
      return;
    } else if (type === "agency") {
      if (bankCode === null) {
        toast("Please select a bank for your commissions", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "error",
        });
        setIsSubmitButtonDisabled(false);
        return;
      } else if (bankAccountNumber === "") {
        toast("Bank account number is empty!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "error",
        });
        setIsSubmitButtonDisabled(false);
        return;
      }
    } else if (type === "employee") {
      if (gender === "") {
        toast("Gender is not specified!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "error",
        });
        setIsSubmitButtonDisabled(false);
        return;
      }
    }

    let data =
      type === "agency"
        ? {
            name: agencyName.trim(),
            phoneNumber: phoneNumber.trim(),
            email: email.trim(),
            username: username.trim(),
            password: password,
            address: address.trim(),
            country: country.trim(),
            state: state.trim(),
            village: village.trim(),
            nin: nin.trim(),
            bankCode: bankCode,
            bankName: bankName,
            bankAccountNumber: bankAccountNumber.trim(),
          }
        : type === "employee"
        ? {
            first_name: firstName.trim(),
            last_name: lastName.trim(),
            phoneNumber: phoneNumber.trim(),
            email: email.trim(),
            username: username.trim(),
            password: password,
            address: address.trim(),
            country: country.trim(),
            state: state.trim(),
            village: village.trim(),
            dateOfBirth: new Date(dob).toISOString().split("T")[0],
            district: district.trim(),
            parentGuardian: parentGuardian.trim(),
            lc1Name: lc1Name.trim(),
            bioData: bioData.trim(),
            gender: gender,
          }
        : {};
    // call the api
    createUser(data);
  };

  useEffect(async () => {
    try {
      let response = await api.getAllBanks("UG"); // UG --> Uganda | KE --> Kenya
      if (response.status === 200) {
        let responseBody = response.data;
        console.log(responseBody.message);
        // console.log(responseBody.data);
        dispatch(setBanksList(responseBody.data));
      }
    } catch (error) {
      if (error.response) {
        console.log("status code " + error.response.status);
        let { message } = error.response.data;
        console.log("errorMessage:", message);
        // alert(message);
      } else {
        console.log("An error occurred. Please try again later");
      }
    }
  }, []);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded-xl bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6
              className="text-blueGray-700 text-xl font-bold"
              style={{
                wordSpacing: "5px",
              }}
            >
              Create account
            </h6>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">
                Account type
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={type}
                label="Account type"
                onChange={handleChangeType}
                disabled
                style={{ fontFamily: "'Hahmlet', serif" }}
              >
                <MenuItem value={"employee"}>Employee</MenuItem>
                <MenuItem value={"agency"}>Recruiting Agency</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={signup}>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              User Information
            </h6>

            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4" hidden={type === "agency"}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    First Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    required={type === "employee"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeFirstName}
                    value={firstName}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4" hidden={type === "agency"}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Last Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    required={type === "employee"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeLastName}
                    value={lastName}
                  />
                </div>
              </div>

              <div
                className="w-full lg:w-12/12 px-4"
                hidden={type === "employee"}
              >
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Agency Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    required={type === "agency"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeAgencyName}
                    value={agencyName}
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    {"Phone Number (add country code) "}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="tel"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangePhoneNumber}
                    value={phoneNumber}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email address <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="email"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeEmail}
                    value={email}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Username <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeUsername}
                    value={username}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="password"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangePassword}
                    value={password}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Location Information
            </h6>
            <div className="flex flex-wrap">
              <div
                className={
                  type === "agency"
                    ? "w-full lg:w-12/12 px-4"
                    : "w-full lg:w-6/12 px-4"
                }
              >
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Kansanga Ggabba road, 248"
                    onChange={handleChangeAddress}
                    value={address}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4" hidden={type === "agency"}>
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    District <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    required={type === "employee"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeDistrict}
                    value={district}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Country <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeCountry}
                    value={country}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeState}
                    value={state}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Village
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeVillage}
                    value={village}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Other Information
            </h6>
            <div className="flex flex-wrap" hidden={type === "employee"}>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    NIN / Passport number{" "}
                    <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    required={type === "agency"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeNIN}
                    value={nin}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Bank name <span className="text-red-600">*</span>
                  </label>
                  <div
                    style={{
                      backgroundColor: "white",
                      border: "0.01px solid white",
                      marginTop: 10,
                      borderRadius: 5,
                    }}
                  >
                    <Autocomplete
                      id="select_bank"
                      options={bankslist}
                      getOptionLabel={(bank) => bank.name} // is like a forEach to fill the select tag options
                      onChange={handleChangeBankCode}
                      style={{
                        marginTop: 4.5,
                        marginLeft: -1,
                        marginRight: -1,
                        marginBottom: -1,
                      }}
                      className="shadow-sm"
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="Bank"
                          variant="outlined"
                          color="primary" // or secondary
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Bank account number <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeBankAccountNumber}
                    value={bankAccountNumber}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap" hidden={type === "agency"}>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Date of Birth <span className="text-red-600">*</span>
                  </label>
                  <input
                    type="date"
                    required={type === "employee"}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeDOB}
                    value={dob}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Gender <span className="text-red-600">*</span>
                  </label>
                  <div class="form-check form-check-inline">
                    <input
                      className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="Male"
                      onChange={handleChangeGender}
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      for="inlineRadio10"
                    >
                      Male
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="Female"
                      onChange={handleChangeGender}
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      for="inlineRadio20"
                    >
                      Female
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Parent's name or guardian
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeParentGuardian}
                    value={parentGuardian}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    LC 1 name
                  </label>
                  <input
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleChangeLC1Name}
                    value={lc1Name}
                  />
                </div>
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Short description of bio data
                  </label>
                  <textarea
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    rows="3"
                    onChange={handleChangeBioData}
                    value={bioData}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="flex items-center flex items-center justify-center mt-4">
              <span className="ml-2 text-sm text-gray-600">
                Already have an account?
                <a
                  style={{ paddingLeft: 3 }}
                  className="underline text-sm text-indigo-600 hover:text-gray-900"
                  href="/"
                >
                  Login
                </a>
              </span>
              <button
                className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                type="submit"
                disabled={isSubmitButtonDisabled}
              >
                <span>Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(CardSignUp);
