import Chart from "chart.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const year1 = new Date().getFullYear();
const year2 = new Date().getFullYear() - 1;
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const months_list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

function CardLineChart({ history }) {
  const invoices = useSelector(({ records: { invoices } }) => invoices);
  const [year1Invoices, setYear1Invoices] = useState({});
  const [year2Invoices, setYear2Invoices] = useState({});

  useEffect(() => {
    // get Monthly invoices
    let year1_invoices = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    };
    let year2_invoices = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    };

    invoices.forEach((invoice) => {
      let invoiceYear = new Date(invoice.createdAt).getFullYear();
      let invoiceMonth = new Date(invoice.createdAt).getMonth() + 1;
      months_list.forEach((month) => {
        if (invoiceMonth != month) return;
        if (invoiceYear === year1) {
          year1_invoices[month] += 1;
        } else if (invoiceYear === year2) {
          year2_invoices[month] += 1;
        }
      });
    });

    setYear1Invoices(year1_invoices);
    setYear2Invoices(year2_invoices);

    //console.log("year1Invoices", year1Invoices);
    //console.log("year2Invoices", year2Invoices);
  }, [invoices]);

  useEffect(() => {
    var config = {
      type: "line",
      data: {
        labels: months,
        datasets: [
          {
            label: year1,
            backgroundColor: "#4c51bf",
            borderColor: "#4c51bf",
            //data: [45, 9, 15, 50, 25, 65, 78, 66, 44, 56, 67, 75],
            data: [
              year1Invoices["1"],
              year1Invoices["2"],
              year1Invoices["3"],
              year1Invoices["4"],
              year1Invoices["5"],
              year1Invoices["6"],
              year1Invoices["7"],
              year1Invoices["8"],
              year1Invoices["9"],
              year1Invoices["10"],
              year1Invoices["11"],
              year1Invoices["12"],
            ],
            fill: false,
          },
          {
            label: year2,
            fill: false,
            backgroundColor: "#fff",
            borderColor: "#fff",
            //data: [10, 60, 15, 31, 37, 40, 68, 86, 74, 56, 60, 87],
            data: [
              year2Invoices["1"],
              year2Invoices["2"],
              year2Invoices["3"],
              year2Invoices["4"],
              year2Invoices["5"],
              year2Invoices["6"],
              year2Invoices["7"],
              year2Invoices["8"],
              year2Invoices["9"],
              year2Invoices["10"],
              year2Invoices["11"],
              year2Invoices["12"],
            ],
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: true,
          text: "Invoices Chart",
          fontColor: "white",
        },
        legend: {
          labels: {
            fontColor: "white",
          },
          align: "end",
          position: "bottom",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Month",
                fontColor: "white",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
                fontColor: "white",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "rgba(255, 255, 255, 0.15)",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    var ctx = document.getElementById("line-chart").getContext("2d");
    if (window.invoicesChart != undefined) {
      window.invoicesChart.destroy(); // destroy previous instance of Chart
    }
    window.invoicesChart = new Chart(ctx, config);
  }, [year1Invoices, year2Invoices]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
        <div className="rounded-t mb-0 px-4 pt-2 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                Overview
              </h6>
              <h2 className="text-white text-xl font-semibold">
                Invoices value
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
            <canvas id="line-chart"></canvas>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(CardLineChart);
