import { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../styles/home_page.css";
import FooterPagePro from "../utils/footer/footer";
import Login from "../utils/login/login";
import VerifyOTP from "../utils/popups/verifyOTP";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import ApiCalls from "../api/apiCalls";
import { setBanksList } from "../actions";
// import { Oval } from 'react-loader-spinner';

const Home = () => {
  const [showOverFlow, setShowOverFlow] = useState(false);
  const dispatch = useDispatch();
  // const [isLoading, setIsLoading] = useState(true);
  let api = new ApiCalls();

  useEffect(() => {
    if (window.innerWidth >= 775) {
      setShowOverFlow(false);
    } else {
      setShowOverFlow(true);
    }
    const handleResize = (event) => {
      //var width = window.innerWidth;
      if (window.innerWidth >= 775) {
        setShowOverFlow(false);
      } else {
        setShowOverFlow(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const loader = () => (
  //   <div className="loader">
  //     <Oval color="#00BFFF" height={80} width={80} />
  //     {/* <Loader type="Circles" color="#EB5D2B" height={80} width={80}  /> */}
  //   </div>
  // );

  useEffect(async () => {
    try {
      let response = await api.getAllBanks("UG"); // UG --> Uganda | KE --> Kenya
      if (response.status === 200) {
        // setIsLoading(false);
        let responseBody = response.data;
        console.log(responseBody.message);
        // console.log(responseBody.data);
        dispatch(setBanksList(responseBody.data));
      }
    } catch (error) {
      if (error.response) {
        console.log("status code " + error.response.status);
        let { message } = error.response.data;
        console.log("errorMessage:", message);
        // alert(message);
      } else {
        console.log("An error occurred. Please try again later");
      }
    }
  }, []);

  return (
    // isLoading ? loader() : (
    <div
      style={{ overflow: showOverFlow ? "auto" : "hidden", maxHeight: "100vh" }}
    >
      <div
        style={{
          width: "100%",
          minHeight: "90.9vh",
          color: "white",
          paddingTop: 40,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
        }}
        className="backgroundSVG"
      >
        <Row>
          <Col
            md={12}
            style={{
              border: "0px solid red",
              minHeight: 300,
              paddingBottom: 20,
            }}
          >
            <Login />
          </Col>
        </Row>
      </div>
      <div
        className="footer-copyright text-center py-3"
        style={{
          backgroundColor: "#3B82F6",
          color: "white",
          minHeight: "8.1vh",
        }}
      >
        <Container fluid>
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <a
            href="https://almaredagency.com"
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            {" "}
            Almared Agency Ltd.
          </a>
        </Container>
      </div>
      {/* <div>
        <FooterPagePro />
      </div> */}
      {/* verify OTP popup */}
      <VerifyOTP />
      <ToastContainer />
    </div>
    // )
  );
};

export default Home;
