import types from "./types/types";

export const setUserData = (payload) => {
  return { type: types.USER.SET_DATA, payload: payload };
};

export const changeUserData = () => {
  return { type: types.USER.CHANGE_DATA, payload: payload };
};

export const setUserProfilePicture = (payload) => {
  return { type: types.USER.SET_USER_PROFILE_PICTURE, payload: payload };
};
