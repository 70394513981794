import React, { useState } from "react";

import { Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  addCommissionToStore,
  commissionAdded,
  showPopup,
} from "../../actions";
import ApiCalls from "../../api/apiCalls";
import { toast } from "react-toastify";

function CreateCommission({ history }) {
  const dispatch = useDispatch();
  const showCreateCommissionPopup = useSelector(
    ({ showCreateCommissionPopup }) => showCreateCommissionPopup
  );
  const authToken = useSelector(({ userData: { token } }) => token);
  const commission = useSelector(({ newCommission }) => newCommission);
  const commissions_currency = useSelector(
    ({ records: { commissions_currency } }) => commissions_currency
  );
  let api = new ApiCalls();

  // form fields
  const [amount, setAmount] = useState("");
  // submit button
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const handleChangeAmount = (event) => {
    setAmount(event.target.value);
  };

  const handleClose = () => {
    dispatch(showPopup("create_commission", false));
  };

  const saveCommission = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    // TODO:
    //commission.agency.id
    setIsSubmitButtonDisabled(true);
    toast.promise(
      api.createCommission(
        {
          amount: amount,
          agencyId: commission.agency.id,
        },
        authToken
      ),
      {
        pending: "Please wait...",
        success: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.status === 200) {
              let responseBody = data.data;
              console.log(responseBody.message);
              dispatch(addCommissionToStore(responseBody.data.commission)); // add it to redux store
              dispatch(commissionAdded());
              //console.log("createdCommission", responseBody.data.commission);
              handleClose();
              return `${responseBody.message}`;
            }
          },
        },
        error: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.response) {
              console.log("status code " + data.response.status);
              let { message } = data.response.data;
              console.log("errorMessage:", message);
              return `${message}`;
            } else {
              console.log("An error occurred, try again later");
              return "An error occurred, try again later";
            }
          },
        },
      }
    );
  };

  return (
    <Modal
      show={showCreateCommissionPopup}
      onHide={handleClose}
      centered
      backdrop={true}
      size="xl"
    >
      <Modal.Header closeButton style={{ fontSize: 40 }}>
        <Modal.Title>Create Commission</Modal.Title>
      </Modal.Header>
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full shadow-md rounded-xl bg-blueGray-100 border-0">
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form onSubmit={saveCommission}>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Commission Details
              </h6>

              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Agency Name
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={commission.agency.name}
                      disabled
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Phone Number
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={commission.agency.phoneNumber}
                      disabled
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Bank
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={commission.agency.bankName}
                      disabled
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Amount in {commissions_currency}
                    </label>
                    <input
                      type="number"
                      required
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-md focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={handleChangeAmount}
                      value={amount}
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center flex items-center justify-center mt-4">
                <button
                  className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                  type="submit"
                  disabled={isSubmitButtonDisabled}
                >
                  <span>Create Commission</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    </Modal>
  );
}

export default withRouter(CreateCommission);
