import React from "react";
import MenuBar from "../utils/menu_side_bar";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard/dashboard";
import Profile from "./dashboard/profile/profile";
import Employee from "./dashboard/employees/employees";
import Invoices from "./dashboard/invoices/invoices";
import Commissions from "./dashboard/commissions/commissions";
import Agencies from "./dashboard/agencies/agencies";
import Settings from "./dashboard/settings/settings";
import Retainers from "./dashboard/retainers/retainers";
import ContactUs from "./dashboard/contact-us/contact-us";
import Employers from "./dashboard/employers/employers";
import Commission from "./dashboard/commissions/Commission";
import Invoice from "./dashboard/invoices/Invoice";

function Menu() {
  return (
    <div style={{ backgroundColor: "#eee" }}>
      <MenuBar />
      <div className="content-wrapper">
        <Switch>
          <Route
            exact
            path="/dashboard/commission/:id"
            component={Commission}
          />
          <Route exact path="/dashboard/invoice/:id" component={Invoice} />
          <Route exact path="/dashboard" component={(props) => <Dashboard />} />
          <Route
            exact
            path="/dashboard/employees"
            component={(props) => <Employee />}
          />
          <Route
            exact
            path="/dashboard/agencies"
            component={(props) => <Agencies />}
          />
          <Route
            exact
            path="/dashboard/employers"
            component={(props) => <Employers />}
          />
          <Route
            exact
            path="/dashboard/profile"
            component={(props) => <Profile />}
          />
          <Route
            exact
            path="/dashboard/invoices"
            component={(props) => <Invoices history={props.history} />}
          />
          <Route
            exact
            path="/dashboard/commissions"
            component={(props) => <Commissions history={props.history} />}
          />

          <Route
            exact
            path="/dashboard/settings"
            component={(props) => <Settings />}
          />

          <Route
            exact
            path="/dashboard/retainers"
            component={(props) => <Retainers />}
          />
          <Route
            exact
            path="/dashboard/contact-us"
            component={(props) => <ContactUs />}
          />
        </Switch>
      </div>
    </div>
  );
}

export default Menu;
