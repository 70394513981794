import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import FooterPagePro from "../utils/footer/footer";
import CardSignUp from "../utils/signUp/signUp";
import VerifyOTP from "../utils/popups/verifyOTP";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUp = ({ history }) => {
  return (
    <>
      <div
        style={{
          minHeight: 60,
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}
        className="bg-indigo-500"
      >
        <Row style={{ minHeight: 60 }}>
          <Col
            lg={8}
            md={8}
            sm={6}
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <span
              style={{
                fontWeight: "lighter",
                wordSpacing: "3px",
                letterSpacing: "1px",
                paddingLeft: 20,
              }}
              className="company-title text-white"
            >
              Cheque-Handler
            </span>
          </Col>
          <Col
            lg={4}
            md={4}
            sm={6}
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <a href="/" className="text-white">
              <button class="hover:bg-white font-semibold hover:text-indigo-500 py-2 px-4 border border-white-500 hover:border-transparent rounded">
                Login
              </button>
            </a>
          </Col>
        </Row>
      </div>
      <div
        className="flex flex-wrap"
        style={{
          minHeight: 450,
          backgroundColor: "#eee",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 40,
        }}
      >
        <div className="w-full lg:w-9/12 px-4">
          <CardSignUp />
        </div>
      </div>
      <FooterPagePro />
      {/* verify OTP popup */}
      <VerifyOTP />
      <ToastContainer />
    </>
  );
};

export default withRouter(SignUp);
