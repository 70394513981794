import React, { useState, useEffect } from "react";
import { MDBContainer, MDBDataTable } from "mdbreact";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createOrUpdateEmployee, showPopup } from "../../../actions";
import EditEmployee from "./../../../utils/popups/editEmployee";
import ApiCalls from "../../../api/apiCalls";

const Status = ({ isActive }) => {
  let badge = isActive ? "bg-green-500" : "bg-red-300";
  return (
    <span
      style={{
        color: "white",
      }}
      className={`badge badge ${badge} table-tbody`}
    >
      {isActive ? "active" : "stopped"}
    </span>
  );
};

const Label = ({ label }) => {
  return (
    <span style={{ cursor: "pointer" }}>
      {label}
      <span
        class="text-blueGray-500 "
        style={{
          marginLeft: 4,
        }}
      >
        ↑↓
      </span>
    </span>
  );
};

const Employees = ({ history }) => {
  const [employeesDataTable, setEmployeesDataTable] = useState({});
  const employees = useSelector(({ records: { employees } }) => employees);
  const employers = useSelector(({ records: { employers } }) => employers);
  const agencies = useSelector(({ records: { agencies } }) => agencies);
  const [scrollY, setScrollY] = useState(false);
  const employeeAddedOrUpdated = useSelector(
    ({ employeeAddedOrUpdated }) => employeeAddedOrUpdated
  );
  const invoices_currency = useSelector(
    ({ records: { invoices_currency } }) => invoices_currency
  );
  const dispatch = useDispatch();
  let api = new ApiCalls();

  const editEmployee = (id) => {
    dispatch(
      createOrUpdateEmployee({
        employeeId: id,
      })
    );
    dispatch(showPopup("edit_employee", true));
  };

  useEffect(() => {
    const displayData = employees.reverse().map((employee) => {
      let employer = employers.find(
        (employer) => employer.id === employee.employer
      );
      let agency = agencies.find((agency) => agency.id === employee.agency);
      return {
        name: employee.first_name + " " + employee.last_name,
        phoneNumber: employee.phoneNumber,
        employer: employer != undefined ? employer.name : "",
        salary:
          employee.monthlySalary != null
            ? `${
                invoices_currency == "USD" ? "$" : invoices_currency
              } ${Number.parseInt(employee.monthlySalary).toLocaleString()}`
            : "",
        isActive: <Status isActive={employee.isActive} />,
        isActiveSearchable: employee.isActive ? "Active" : "Stopped",
        bankName: employee.bankName,
        bankAccountNumber: employee.bankAccountNumber,
        agency: agency != undefined ? agency.name : "",
        edit: (
          <button
            class="hover:bg-indigo-500 text-indigo-500 font-semibold hover:text-white py-1 px-3 border border-indigo-500 hover:border-transparent rounded"
            onClick={() => {
              editEmployee(employee.id);
            }}
          >
            Edit
          </button>
        ),
      };
    });

    setEmployeesDataTable({
      columns: [
        {
          label: <Label label="Name" />,
          field: "name",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Employer" />,
          field: "employer",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Salary" />,
          field: "salary",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Agency" />,
          field: "agency",
          sort: "asc",
          width: 100,
        },
        {
          label: <Label label="Status" />,
          field: "isActive",
          sort: "asc",
          width: 100,
        },
        {
          // label: <Label label="Edit" />,
          label: "Edit",
          field: "edit",
          sort: "asc",
          width: 100,
        },
      ],
      rows: displayData,
    });
  }, [employees, employeeAddedOrUpdated]);

  useEffect(() => {
    if (window.innerWidth >= 775) {
      setScrollY(false);
    } else {
      setScrollY(true);
    }
    const handleResize = (event) => {
      var width = window.innerWidth;
      if (width >= 775) {
        setScrollY(false);
      } else {
        setScrollY(true);
      }
    };
    window.addEventListener("resize", handleResize);

    // cleanup this component // when component unmounts // run this callback
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // runs only once // empty dependency list

  return (
    <>
      <div>
        <div className="div-employees-page-title">
          <span
            className="employees-page-title text-indigo-500"
            style={{
              fontWeight: "lighter",
              wordSpacing: "4px",
              letterSpacing: "1px",
              lineHeight: 1.8,
            }}
          >
            Employees
          </span>
          <button
            style={{
              fontWeight: "lighter",
              wordSpacing: "1px",
              lineHeight: 1,
              marginLeft: "8vw",
            }}
            class="bg-indigo-500 hover:bg-blueGray-500 text-white font-bold py-2 px-3 rounded-full"
            onClick={() => {
              editEmployee(null);
            }}
          >
            <i class="fa fa-plus" aria-hidden="true" />
            New Employee
          </button>
        </div>
        <div className="padding-data-table padding-for-bottom-navigation">
          <MDBContainer
            className="shadow-sm"
            style={{
              backgroundColor: "white",
              paddingTop: 10,
              borderRadius: 10,
              paddingBottom: 10,
              paddingRight: 0,
              paddingLeft: 0,
            }}
          >
            <MDBDataTable
              borderless
              maxHeight="100vh"
              hover
              responsive
              responsiveSm
              responsiveMd
              responsiveLg
              responsiveXl
              theadColor="#5072a7"
              data={employeesDataTable}
              id="tableID"
              exportToCSV
              noBottomColumns
              pagesAmount={3}
              entriesOptions={[20, 50, 100]}
            />
          </MDBContainer>
        </div>
      </div>
      <ToastContainer />
      <EditEmployee />
    </>
  );
};

export default withRouter(Employees);
