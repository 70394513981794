import { useEffect, useState } from "react";
import "../../styles/output.css"; // tailwindcss styles on this component
import CardStats from "../../utils/Cards/CardStats";
import CardLineChart from "../../utils/Cards/CardLineChart";
import CardBarChart from "../../utils/Cards/CardBarChart";
import CardEmployees from "../../utils/Cards/CardEmployees";
import CardAgency from "../../utils/Cards/CardAgency";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import CardInvoices from "../../utils/Cards/cardInvoices";
import CardEmployers from "../../utils/Cards/cardEmployers";

const Dashboard = ({ history }) => {
  const [agenciesStat, setAgenciesStat] = useState({
    percent: "0",
    arrow: "down",
  });
  const [employeesStat, setEmployeesStat] = useState({
    percent: "0",
    arrow: "down",
  });
  const [invoicesStat, setInvoicesStat] = useState({
    percent: "0",
    arrow: "down",
  });
  const [commissionsStat, setCommissionsStat] = useState({
    percent: "0",
    arrow: "down",
  });
  const user_data = useSelector((state) => state.userData);
  const user_group_data = useSelector(
    (state) => state.userData.user_data.user_group_data
  );
  const records = useSelector((state) => state.records);

  const month1 = new Date().getMonth() + 1; // current month
  const month2 = month1 - 1;
  const currentYear = new Date().getFullYear(); // current year

  useEffect(() => {
    const { employees, agencies, invoices, commissions } = records;

    let employees_stats = getStats(employees);
    let agencies_stats = getStats(agencies);
    console.log("agencies_stats", agencies_stats);
    let invoices_stats = getStats(invoices);
    let commissions_stats = getStats(commissions);

    setAgenciesStat({
      percent: agencies_stats.percent.toLocaleString(),
      arrow: agencies_stats.arrow,
    });
    setEmployeesStat({
      percent: employees_stats.percent.toLocaleString(),
      arrow: employees_stats.arrow,
    });
    setInvoicesStat({
      percent: invoices_stats.percent.toLocaleString(),
      arrow: invoices_stats.arrow,
    });
    setCommissionsStat({
      percent: commissions_stats.percent.toLocaleString(),
      arrow: commissions_stats.arrow,
    });
  }, [records]);

  const getStats = (records) => {
    let countMonth1 = 0;
    let countMonth2 = 0;
    records.forEach((record) => {
      let createdAtMonth = new Date(record.createdAt).getMonth() + 1;
      let createdAtYear = new Date(record.createdAt).getFullYear();
      if (createdAtYear === currentYear) {
        if (createdAtMonth === month1) {
          countMonth1 += 1;
        } else if (createdAtMonth === month2) {
          countMonth2 += 1;
        }
      }
    });

    let total = records.length;
    let percentMonth1 = (countMonth1 * 100) / total;
    let percentMonth2 = (countMonth2 * 100) / total;
    let percent = percentMonth1 - percentMonth2;
    let arrow;
    if (percent > 0) {
      // rate increased in comparison to previous month
      arrow = "up";
    } else if (percent === 0) {
      arrow = "flat";
    } else {
      // rate decreased in comparison to previous month
      arrow = "down";
    }
    return { percent: percent, arrow: arrow };
  };

  return (
    <>
      {user_group_data.type === "supersite" ? (
        <div
          style={{
            paddingTop: 10,
            minHeight: "100vh",
          }}
          // className="relative bg-indigo-800 h-screen"
          className="relative h-screen"
        >
          <div style={{ paddingBottom: 5, paddingLeft: 30 }}></div>
          <div
            className="flex flex-wrap card-stats"
            style={{ backgroundColor: "#eee" }}
          >
            <div className="w-full lg:w-6/12 xl:w-3/12 px-3">
              <CardStats
                statSubtitle="EMPLOYEES"
                statTitle={Number.parseInt(
                  records.employees.length
                ).toLocaleString()}
                statArrow={employeesStat.arrow}
                statPercent={employeesStat.percent}
                statPercentColor={
                  employeesStat.arrow === "down"
                    ? "text-red-500"
                    : "text-emerald-500"
                }
                statDescripiron="Since last month"
                statIconName="fas fa-users"
                statIconColor="bg-green-500"
              />
            </div>
            <div className="w-full lg:w-6/12 xl:w-3/12 px-3">
              <CardStats
                statSubtitle="INVOICES"
                statTitle={Number.parseInt(
                  records.invoices.length
                ).toLocaleString()}
                statArrow={invoicesStat.arrow}
                statPercent={invoicesStat.percent}
                statPercentColor={
                  invoicesStat.arrow === "down"
                    ? "text-red-500"
                    : "text-emerald-500"
                }
                statDescripiron="Since last month"
                statIconName="far fa-chart-bar"
                statIconColor="bg-pink-500"
              />
            </div>
            <div className="w-full lg:w-6/12 xl:w-3/12 px-3">
              <CardStats
                statSubtitle="AGENCIES"
                statTitle={Number.parseInt(
                  records.agencies.length
                ).toLocaleString()}
                statArrow={agenciesStat.arrow}
                statPercent={agenciesStat.percent}
                statPercentColor={
                  agenciesStat.arrow === "down"
                    ? "text-red-500"
                    : "text-emerald-500"
                }
                statDescripiron="Since last month"
                statIconName="fa fa-building"
                statIconColor="bg-purple-500"
              />
            </div>
            <div className="w-full lg:w-6/12 xl:w-3/12 px-3">
              <CardStats
                statSubtitle="COMMISSIONS"
                statTitle={Number.parseInt(
                  records.commissions.length
                ).toLocaleString()}
                statArrow={commissionsStat.arrow}
                statPercent={commissionsStat.percent}
                statPercentColor={
                  commissionsStat.arrow === "down"
                    ? "text-red-500"
                    : "text-emerald-500"
                }
                statDescripiron="Since last month"
                statIconName="fas fa-chart-pie"
                statIconColor="bg-blue-500"
              />
            </div>
          </div>
          <div className="px-3 md:px-10 mx-auto w-full pt-2 bg-gray-100">
            <div className="flex flex-wrap">
              <div className="w-full xl:w-8/12 mb-2 xl:mb-0 pl-1 pr-1 xl:pl-4 xl:pr-4">
                <CardLineChart />
              </div>
              <div className="w-full xl:w-4/12 mb-2 xl:mb-0 pl-1 pr-1 xl:pl-4 xl:pr-4">
                <CardBarChart />
              </div>
            </div>
            <div className="flex flex-wrap mt-2">
              <div className="w-full xl:w-6/12 mb-2 xl:mb-0 pl-1 pr-1 xl:pl-4 xl:pr-4">
                <CardEmployees />
              </div>
              <div className="w-full xl:w-6/12 mb-2 xl:mb-0 pl-1 pr-1 xl:pl-4 xl:pr-4">
                <CardAgency />
              </div>
            </div>
            <div className="flex flex-wrap mt-2 padding-for-dashboard">
              <div className="w-full xl:w-6/12 mb-2 xl:mb-0 pl-1 pr-1 xl:pl-4 xl:pr-4">
                <CardInvoices />
              </div>
              <div className="w-full xl:w-6/12 pl-1 pr-1 xl:pl-4 xl:pr-4">
                <CardEmployers />
              </div>
            </div>
          </div>
        </div>
      ) : user_group_data.type === "agency" ? (
        <div
          style={{
            paddingTop: 10,
            minHeight: "100vh",
          }}
          className="relative h-screen"
        >
          <div style={{ paddingBottom: 5, paddingLeft: 30 }}></div>
          <div
            className="flex flex-wrap card-stats"
            style={{ backgroundColor: "#eee" }}
          >
            <div className="w-full lg:w-6/12 xl:w-4/12 px-3">
              <CardStats
                statSubtitle="EMPLOYEES"
                statTitle={Number.parseInt(
                  records.employees.length
                ).toLocaleString()}
                statArrow={employeesStat.arrow}
                statPercent={employeesStat.percent}
                statPercentColor={
                  employeesStat.arrow === "down"
                    ? "text-red-500"
                    : "text-emerald-500"
                }
                statDescripiron="Since last month"
                statIconName="fas fa-users"
                statIconColor="bg-green-500"
              />
            </div>
            <div className="w-full lg:w-6/12 xl:w-4/12 px-3">
              <CardStats
                statSubtitle="INVOICES"
                statTitle={Number.parseInt(
                  records.invoices.length
                ).toLocaleString()}
                statArrow={invoicesStat.arrow}
                statPercent={invoicesStat.percent}
                statPercentColor={
                  invoicesStat.arrow === "down"
                    ? "text-red-500"
                    : "text-emerald-500"
                }
                statDescripiron="Since last month"
                statIconName="far fa-chart-bar"
                statIconColor="bg-pink-500"
              />
            </div>
            <div className="w-full lg:w-6/12 xl:w-4/12 px-3">
              <CardStats
                statSubtitle="COMMISSIONS"
                statTitle={Number.parseInt(
                  records.commissions.length
                ).toLocaleString()}
                statArrow={commissionsStat.arrow}
                statPercent={commissionsStat.percent}
                statPercentColor={
                  commissionsStat.arrow === "down"
                    ? "text-red-500"
                    : "text-emerald-500"
                }
                statDescripiron="Since last month"
                statIconName="fas fa-chart-pie"
                statIconColor="bg-blue-500"
              />
            </div>
          </div>
          <div className="px-3 md:px-10 mx-auto w-full pt-2 bg-gray-100">
            <div className="flex flex-wrap">
              <div className="w-full xl:w-8/12 mb-2 xl:mb-0 pl-1 pr-1 xl:pl-4 xl:pr-4">
                <CardLineChart />
              </div>
              <div className="w-full xl:w-4/12 mb-2 xl:mb-0 pl-1 pr-1 xl:pl-4 xl:pr-4">
                <CardBarChart />
              </div>
            </div>
            <div className="flex flex-wrap mt-2 padding-for-dashboard">
              <div className="w-full xl:w-6/12 mb-2 xl:mb-0 pl-1 pr-1 xl:pl-4 xl:pr-4">
                <CardEmployees />
              </div>
              <div className="w-full xl:w-6/12 mb-2 xl:mb-0 pl-1 pr-1 xl:pl-4 xl:pr-4">
                <CardInvoices />
              </div>
            </div>
          </div>
        </div>
      ) : user_group_data.type === "employee" ? (
        <div
          style={{
            paddingTop: 25,
            paddingLeft: 30,
            minHeight: "100vh",
            backgroundColor: "#eee",
          }}
        >
          Employee
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(Dashboard);
