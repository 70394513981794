import types from "../actions/types/types";

export const createInvoiceDataReducer = (
  state = {
    inv: {
      id: "",
      amount: "",
      status: "",
      createdAt: "",
    },
    payment: {
      id: "",
      amount: "",
      transactionFee: "",
      transactionId: "",
      status: "",
      comment: "",
    },
    employer: {
      name: "",
      email: "",
      id: "",
    },
    employee: {
      name: "",
      id: "",
      monthlySalary: "",
    },
    agency: {
      id: null,
    },
  },
  action
) => {
  switch (action.type) {
    case types.SHOW_INVOICE_TO_STORE:
      // return {
      //   ...state,
      //   payload: action.payload,
      // };
      return action.payload;
    default:
      return state;
  }
}