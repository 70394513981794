import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";

// components

function CardEmployees({ history }) {
  const [employeesData, setEmployeesData] = useState([]);
  const agencies = useSelector(({ records: { agencies } }) => agencies);
  const employees = useSelector(({ records: { employees } }) => employees);
  const employers = useSelector(({ records: { employers } }) => employers);
  useEffect(() => {
    let employeesRecords;
    if (employees.length > 5) {
      employeesRecords = employees.slice(0, 5); // pick first five elements only
    } else {
      employeesRecords = employees;
    }

    const data = employeesRecords.map((employee) => {
      let agency = agencies.find((agency) => agency.id === employee.agency);
      let employer = employers.find(
        (employer) => employer.id === employee.employer
      );
      return {
        name: (
          <span style={{ wordSpacing: 3 }}>
            {employee.first_name + " " + employee.last_name}
          </span>
        ),
        phoneNumber: employee.phoneNumber,
        agency: agency != undefined ? agency.name : "",
        employer: employer != undefined ? employer.name : "",
      };
    });
    setEmployeesData(data);
  }, [employees]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-md rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                List of Employees
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={(event) => {
                  history.push("/dashboard/employees");
                }}
              >
                See all
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse ">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Name
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Phone Number
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Employer
                </th>
                {/* <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Agency
                </th> */}
              </tr>
            </thead>
            <tbody>
              {employeesData.map((employee) => (
                <tr>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {employee.name}
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {employee.phoneNumber}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {employee.employer}
                  </td>
                  {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {employee.agency}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default withRouter(CardEmployees);
