import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

const Commission = () => {
  const item = useSelector((state) => state.showCommissionData);

  let date = `${item.commission.createdAt}`.substr(4, 11);

  const Status = ({ status }) => {
    const [showTextStatus, setShowTextStatus] = useState(false);
    let badge =
      status === "sent"
        ? "blue-500"
        : status === "paid"
        ? "purple-500"
        : status === "transfering"
        ? "yellow-500"
        : status === "transfer failed" || status === "payment failed"
        ? "red-500"
        : status === "withdrawn"
        ? "green-500"
        : "blue-500";

    useEffect(() => {
      if (window.innerWidth >= 775) {
        setShowTextStatus(false);
      } else {
        setShowTextStatus(true);
      }
      const handleResize = (event) => {
        //var width = window.innerWidth;
        if (window.innerWidth >= 775) {
          setShowTextStatus(false);
        } else {
          setShowTextStatus(true);
        }
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
      <div
        style={{
          color: "black",
          cursor: "pointer",
        }}
        className={`table-tbody d-flex justify-content-md-end  `}
      >
        <div
          // md={2}
          style={{
            display: "flex",
            alignItems: "center",
            fontFamily: "cursive",
            paddingTop: 5,
          }}
        >
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // boxShadow: "2px 2px",
            }}
            className={`bg-${badge} text-${badge} mr-5`}
            title={status}
          >
            o
          </div>
        </div>
        <div>{status.toUpperCase()}</div>
      </div>
    );
  };

  return (
    <div className="commission pt-2 pb-5">
      <div className="commission-top px-4 py-2">
        <div className="commission-topCenter">
          <p className="fw-bold align-items-center fs-3 p-3 text-center text-indigo-500">
            {" "}
            Commission Details{" "}
          </p>
        </div>
        <div className="commission-topLine d-flex flex-md-row flex-column-reverse justify-content-between align-items-center text-center">
          <div className="commission-topLeft text-start col-12 col-md-7 col-lg-8">
            <h3 className="fw-bold">{item.commission.amount}</h3>
          </div>
          <div className="commission-topright d-flex flex-row flex-md-column justify-content-between text-md-end col-12 col-md-5 col-lg-4">
            <p> {date} </p>
          </div>
        </div>
        <div className="commission-bottomLine">
          <p> Some Information about this Commission </p>
        </div>
      </div>
      <div className="commission-middle">
        <div className="card bg-light d-flex flex-lg-row py-3 mx-4 justify-content-between mb-5">
          <div className="card-left col-lg-6">
            <div className="card-detail1 mx-4 d-flex flex-row flex-2">
              <p className="card-tittle w-50">Invoice Reference: </p> &nbsp;
              &nbsp;
              <p className="card-data fst-italic">
                {" "}
                {item.payment.transactionId.toLowerCase()}{" "}
              </p>
            </div>
            <div className="card-detail1 mx-4 d-flex flex-row flex-1">
              <p className="card-tittle font-weight-bold w-50">Agency Name: </p>{" "}
              &nbsp; &nbsp;
              <p className="fst-italic"> {item.agency.name} </p>
            </div>
            <div className="card-detail1 mx-4 d-flex flex-row flex-1">
              <p className="card-tittle font-weight-bold w-50">
                Employee Name:{" "}
              </p>{" "}
              &nbsp; &nbsp;
              <p className="fst-italic"> {item.employee.name} </p>
            </div>
            {/* <div className="card-detail1 mx-4  d-flex flex-row flex-1">
              <p className="card-tittle w-50">Comment: </p> &nbsp; &nbsp;
              <p className="fst-italic w-50">
                {" "}
                {item.payment.comment === null
                  ? "No Comments"
                  : item.payment.comment}{" "}
              </p>
            </div> */}
          </div>
          <div className="card-right col-lg-6">
            <div className="card-detail1 mx-4 d-flex flex-row flex-1">
              <p className="card-tittle w-50">Commission Amount: </p> &nbsp;
              &nbsp;
              <p className="fst-italic"> {item.commission.amount} </p>
            </div>
            {/* <div className="card-detail1 mx-4  d-flex flex-row flex-1">
              <p className="card-tittle w-25">Amount In: </p> &nbsp; &nbsp;
              <p>USD $</p>
            </div> */}
            <div className="card-detail1 mx-4  d-flex flex-row flex-1">
              <p className="card-tittle w-50">Invoice Amount: </p> &nbsp; &nbsp;
              <p className="fst-italic"> {item.invoice.amount} </p>
            </div>
            <div className="card-detail1 mx-4  d-flex flex-row flex-1">
              <p className="card-tittle w-50">Invoice Status: </p> &nbsp; &nbsp;
              <p hidden={item.invoice.status === ""}>
                <Status status={item.invoice.status} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commission;
