import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAutoInvoicing } from "../../../actions";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import ApiCalls from "../../../api/apiCalls";

const Settings = () => {
  const [showCreateSuperSite, setShowCreateSuperSite] = useState(false);
  const auto_invoicing = useSelector(
    ({ records: { auto_invoicing } }) => auto_invoicing
  );
  const [automaticInvoicing, setAutomaticInvoicing] = useState(false);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const authToken = useSelector(({ userData: { token } }) => token);
  let api = new ApiCalls();

  const dispatch = useDispatch();

  const handleChangeAutoInvoicing = (event) => {
    if (!automaticInvoicing) {
      turnOnAutoInvoicing();
    } else {
      // show toast // auto invoicing is already running
      toast("Auto-invoicing is already running", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "warning",
      });
    }
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    setAutomaticInvoicing(auto_invoicing);
  }, [auto_invoicing]);

  const turnOnAutoInvoicing = async () => {
    toast.promise(api.startAutoInvoicing(authToken), {
      pending: "Please wait...",
      success: {
        render({ data }) {
          if (data.status === 200) {
            let responseBody = data.data;
            console.log(responseBody.message);
            setAutomaticInvoicing(true);
            dispatch(setAutoInvoicing(true));
            return `${responseBody.message}`;
          }
        },
      },
      error: {
        render({ data }) {
          if (data.response) {
            console.log("status code " + data.response.status);
            let { message } = data.response.data;
            console.log("errorMessage:", message);
            return `${message}`;
          } else {
            console.log("An error occurred, try again later");
            return "An error occurred, try again later";
          }
        },
      },
    });
  };

  const createSuperSite = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsSubmitButtonDisabled(true);
    toast.promise(
      api.createSuperSite(
        {
          name: name,
          email: email,
          phoneNumber: phoneNumber,
          username: username,
          password: password,
        },
        authToken
      ),
      {
        pending: "Please wait...",
        success: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.status === 200) {
              let responseBody = data.data;
              console.log(responseBody.message);
              setShowCreateSuperSite(false);
              // clear form fields
              setName("");
              setEmail("");
              setPhoneNumber("");
              setUsername("");
              setPassword("");
              return `${responseBody.message}`;
            }
          },
        },
        error: {
          render({ data }) {
            setIsSubmitButtonDisabled(false);
            if (data.response) {
              console.log("status code " + data.response.status);
              let { message } = data.response.data;
              console.log("errorMessage:", message);
              return `${message}`;
            } else {
              console.log("An error occurred, try again later");
              return "An error occurred, try again later";
            }
          },
        },
      }
    );
  };

  return (
    <>
      <div
        className="invoices-page-title text-indigo-500 pl-6 xl:pl-10"
        style={{
          height: 100,
          wordSpacing: "5px",
          letterSpacing: "1px",
          lineHeight: 1.8,
          fontWeight: "500",
        }}
      >
        System Settings
      </div>
      <div className="relative flex flex-col min-w-0 break-words w-full border-0 pl-10 xl:pl-20">
        <div className="flex-auto py-2">
          <h6 className="text-blueGray-600 text-sm font-bold uppercase">
            Automatic Invoicing
          </h6>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 pr-4 xl:mr-10 text-blueGray-500 text-md">
              The Aut-Invoicing system is responsible for genarating invoices
              for active employees, sends invoices to employers and calculates
              commissions for recruiting agencies.
            </div>
            <div className="w-full lg:w-4/12 pr-4 pt-2">
              <div class="form-check form-switch">
                <input
                  class="form-check-input auto-invocing-input appearance-none -ml-10 rounded-full float-left align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  style={{ width: 40, height: 20 }}
                  checked={automaticInvoicing}
                  onChange={handleChangeAutoInvoicing}
                />
              </div>
            </div>
          </div>
          <hr className="mt-6 border-b-1 border-blueGray-300 mr-10 xl:mr-40" />
          <h6 className="text-blueGray-600 text-sm font-bold uppercase">
            Add supersite
          </h6>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-12/12 pr-4 xl:mr-10 text-blueGray-500 text-md pb-10">
              A Supersite has all admistrative privileges on the system.{" "}
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                className="text-md text-indigo-500"
                onClick={() => {
                  setShowCreateSuperSite(true);
                }}
              >
                create supersite
              </span>
            </div>
          </div>
          <div
            hidden={!showCreateSuperSite}
            className="w-full lg:w-8/12 padding-for-bottom-navigation"
          >
            <form onSubmit={createSuperSite}>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 pr-8">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Supersite Name
                    </label>
                    <input
                      type="text"
                      required
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={handleChangeName}
                      value={name}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-6/12 pr-8">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      {"Phone Number (add country code)"}
                    </label>
                    <input
                      type="tel"
                      required
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={handleChangePhoneNumber}
                      value={phoneNumber}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 pr-8">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email address
                    </label>
                    <input
                      type="email"
                      required
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={handleChangeEmail}
                      value={email}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 pr-8">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      required
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={handleChangeUsername}
                      value={username}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 pr-8">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="text"
                      required
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={handleChangePassword}
                      value={password}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center flex items-center mt-4">
                <button
                  className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                  type="submit"
                  disabled={isSubmitButtonDisabled}
                >
                  <span>Create Supersite</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Settings;
