import React, { useEffect, useState } from "react";

import { Image, Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  addOrModifyAgencyToStore,
  agencyAddedOrUpdated,
  showPopup,
} from "../../actions";
import ApiCalls from "../../api/apiCalls";
import { toast } from "react-toastify";
import avatar from "../../images/agency_avatar.png";
import logo from "../../images/cheque-handler.jpg";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function EditAgency({ history }) {
  const dispatch = useDispatch();
  const showEditAgencyPopup = useSelector(
    ({ showEditAgencyPopup }) => showEditAgencyPopup
  );
  const authToken = useSelector(({ userData: { token } }) => token);
  const agencies = useSelector(({ records: { agencies } }) => agencies);
  const users = useSelector(({ records: { users } }) => users);
  const editAgency = useSelector(({ editAgency }) => editAgency);
  const user_group_data = useSelector(
    (state) => state.userData.user_data.user_group_data
  );
  const bankslist = useSelector(({ bankslist }) => bankslist);
  const AddedOrUpdated = useSelector(
    ({ agencyAddedOrUpdated }) => agencyAddedOrUpdated
  );
  const [editType, setEditType] = useState("update");
  // agency form fields
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [village, setVillage] = useState("");
  const [nin, setNIN] = useState(""); // NIN / Passport Number
  const [bankName, setBankName] = useState(null);
  const [bankCode, setBankCode] = useState(null);
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  // submit button
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const [autoCompleteBank, setAutoCompleteBank] = useState({
    code: "",
    name: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const [imageStatus, setImageStatus] = useState("Add Image");

  let api = new ApiCalls();

  const handleChangeName = (event) => {
    setName(event.target.value);
  };
  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  };
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleChangeAddress = (event) => {
    setAddress(event.target.value);
  };
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleChangeState = (event) => {
    setState(event.target.value);
  };
  const handleChangeVillage = (event) => {
    setVillage(event.target.value);
  };
  const handleChangeNIN = (event) => {
    setNIN(event.target.value);
  };
  const handleChangeBankCode = (event, bank) => {
    // if an object is selected
    if (bank) {
      setBankCode(bank.code);
      setBankName(bank.name);
      setAutoCompleteBank(bank);
    } else {
      // if an object is selected
      setBankCode(null);
      setBankName(null);
      setAutoCompleteBank({ code: "", name: "" });
    }
  };
  const handleChangeBankAccountNumber = (event) => {
    setBankAccountNumber(event.target.value);
  };

  const handleClose = () => {
    dispatch(showPopup("edit_agency", false));
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);

    let file = event.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
    if (file && ALLOWED_TYPES.includes(file.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(`${reader.result}`);
      };
      reader.readAsDataURL(file);
    } else {
      console.log("something Went Wrong!");
    }
  };

  const saveAgency = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmitButtonDisabled(true);

    let formData = new FormData();
    formData.append(
      "agencyId",
      editAgency.agencyId != null ? editAgency.agencyId.toString() : ""
    );
    formData.append("name", name);
    formData.append("phoneNumber", phoneNumber);
    formData.append("email", email);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("address", address);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("village", village);
    formData.append("nin", nin);
    formData.append("bankCode", bankCode);
    formData.append("bankName", bankName);
    formData.append("bankAccountNumber", bankAccountNumber);
    // the selected file
    if (selectedFile != null) {
      formData.append("profilePicture", selectedFile);
      // console.log(formData.get("profilePicture").name);
    }

    toast.promise(api.updateAgency(formData, authToken), {
      pending: "Please wait...",
      success: {
        render({ data }) {
          setIsSubmitButtonDisabled(false);
          if (data.status === 200) {
            let responseBody = data.data;
            console.log(responseBody.message);
            dispatch(addOrModifyAgencyToStore(responseBody.data));
            dispatch(agencyAddedOrUpdated());
            handleClose();
            return `${responseBody.message}`;
          }
        },
      },
      error: {
        render({ data }) {
          setIsSubmitButtonDisabled(false);
          if (data.response) {
            console.log("status code " + data.response.status);
            let { message } = data.response.data;
            console.log("errorMessage:", message);
            return `${message}`;
          } else {
            console.log("An error occurred, try again later");
            return "An error occurred, try again later";
          }
        },
      },
    });
  };

  useEffect(() => {
    if (editAgency.agencyId != null) {
      let agency = agencies.find((agency) => agency.id === editAgency.agencyId);

      setEditType("update"); // update fields

      setName(agency.name);
      setProfilePicture(
        agency.profilePicture != null
          ? `${api.SERVER}${agency.profilePicture}`
          : ""
      );
      setImageStatus(
        profilePicture != avatar ? "Change Image" : `${imageStatus}`
      );
      let user = users.find((user) => user.id === agency.user);
      if (user) {
        setUsername(user.username);
        setEmail(user.email);
      } else {
        setUsername("");
        setEmail("");
      }

      setPhoneNumber(agency.phoneNumber != null ? agency.phoneNumber : "");
      setAddress(agency.address != null ? agency.address : "");
      setCountry(agency.country != null ? agency.country : "");
      setState(agency.state != null ? agency.state : "");
      setVillage(agency.village != null ? agency.village : "");
      setBankName(agency.bankName); // if bankName --> null // it's fine here
      setBankCode(agency.bankCode); // if bankCode --> null // it's fine here
      setBankAccountNumber(
        agency.bankAccountNumber != null ? agency.bankAccountNumber : ""
      );
      if (agency.bankCode != null) {
        let bankData = bankslist.find((bank) => bank.code === agency.bankCode);
        setAutoCompleteBank(bankData);
      } else {
        setAutoCompleteBank({ code: "", name: "" });
      }
      //
      setNIN(agency.nin != null ? agency.nin : "");
      // ...
    } else {
      setEditType("create"); // create new agency // empty fields

      setName("");
      setProfilePicture("");
      setUsername("");
      setEmail("");
      setPhoneNumber("");
      setAddress("");
      setCountry("");
      setState("");
      setVillage("");
      setBankCode(null);
      setBankName(null);
      setBankAccountNumber("");
      setNIN("");
      // ...
    }

    // cleanup this component // when component unmounts // run this callback
    return () => {
      setUsername(""); // just to enable editting (disabled input)
      setEmail("");
      setAutoCompleteBank({ code: "", name: "" });
      setPassword("");
      setProfilePicture("");
      setPhoneNumber("");
      setAddress("");
      setCountry("");
      setState("");
      setVillage("");
      setBankAccountNumber("");
      setNIN("");
    };
  }, [editAgency, AddedOrUpdated, bankslist]);

  return (
    <Modal
      show={showEditAgencyPopup}
      onHide={handleClose}
      centered
      backdrop={false}
      size="xl"
    >
      <form onSubmit={saveAgency}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#eee",
            fontSize: 40,
            position: "sticky",
            top: 0,
            zIndex: 2,
          }}
        >
          {/* <Modal.Title>
            {editType === "update" ? "Update Agency" : "Create Agency"}
          </Modal.Title> */}
          <button
            className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center text-sm focus:outline-none transition hover:shadow-md shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
            disabled={isSubmitButtonDisabled}
            type="submit"
          >
            <span>
              {editType === "update" ? "Update Agency" : "Save Agency"}
            </span>
          </button>
        </Modal.Header>
        <div className="agency-details-container">
          <div className="mr-4 ml-4 lg:mr-20 lg:ml-20 xl:mr-30 lx:ml-30">
            <div className="relative flex flex-col min-w-0 break-words w-full rounded-xl bg-blueGray-200 border-0 pt-20">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                {/* <form onSubmit={saveAgency}> */}
                <h6 className="text-blueGray-500 text-sm mt-3 mb-6 font-bold uppercase">
                  Agency Details
                </h6>

                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangeName}
                        value={name}
                      />
                    </div>
                  </div>

                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        {"Phone Number (add country code)"}
                      </label>
                      <input
                        type="tel"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangePhoneNumber}
                        value={phoneNumber}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangeEmail}
                        value={email}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Username
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangeUsername}
                        value={username}
                        disabled={editType === "update"}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Password
                      </label>
                      <input
                        type="text"
                        required={editType === "create"}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangePassword}
                        value={password}
                      />
                    </div>
                  </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-500 text-sm mt-3 mb-6 font-bold uppercase">
                  Contact Information
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-400 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Kansanga Ggabba road, 248"
                        onChange={handleChangeAddress}
                        value={address}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Country
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangeCountry}
                        value={country}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        State
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangeState}
                        value={state}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Village
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangeVillage}
                        value={village}
                      />
                    </div>
                  </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-500 text-sm mt-3 mb-6 font-bold uppercase">
                  Payment Information
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Bank name
                      </label>
                      <div
                        style={{
                          backgroundColor: "white",
                          border: "0.01px solid white",
                          marginTop: 10,
                          borderRadius: 5,
                        }}
                      >
                        <Autocomplete
                          id="select_bank"
                          options={bankslist}
                          getOptionLabel={(bank) => bank.name} // is like a forEach to fill the select tag options
                          onChange={handleChangeBankCode}
                          // value --> default selected bank // "current bank"
                          // value={bankslist.find(
                          //   (bank) => bank.code === bankCode
                          // )}
                          value={autoCompleteBank}
                          style={{
                            marginTop: 4.5,
                            marginLeft: -1,
                            marginRight: -1,
                            marginBottom: -1,
                          }}
                          className="shadow-sm"
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="Bank"
                              variant="outlined"
                              required
                              color="primary" // or secondary
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Bank account number
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangeBankAccountNumber}
                        value={bankAccountNumber}
                      />
                    </div>
                  </div>
                </div>

                <hr className="mt-6 border-b-1 border-blueGray-300" />

                <h6 className="text-blueGray-500 text-sm mt-3 mb-6 font-bold uppercase">
                  Other Information
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        NIN / Passport number
                      </label>
                      <input
                        type="text"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangeNIN}
                        value={nin}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center flex items-center justify-center mt-4">
                  <button
                    className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                    type="submit"
                    disabled={isSubmitButtonDisabled}
                  >
                    <span>
                      {editType === "update"
                        ? "Update Agency"
                        : "Create Agency"}
                    </span>
                  </button>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
          <div className="agency-profile-picture-container">
            <div className="agency-profile-picture">
              <input
                type="file"
                onChange={handleFileChange}
                name="profileImage"
                id="photoFile"
                className="d-none"
              />
              <label
                className="profile-button w-100 h-100"
                role="button"
                htmlFor="photoFile"
              >
                <Image
                  src={profilePicture != "" ? profilePicture : avatar}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "50%",
                  }}
                  alt="Loading..."
                />
                <div className="profile-image"> <span> {imageStatus} </span></div>
              </label>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default withRouter(EditAgency);
