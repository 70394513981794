import types from "../actions/types/types";

export const recordsReducer = (
  state = {
    employees: [],
    agencies: [],
    invoices: [],
    commissions: [],
    employers: [],
    payments: [],
    wallets: [],
    users: [],
    auto_invoicing: false,
    invoices_currency: "USD",
    commissions_currency: "UGX",
  },
  action
) => {
  switch (action.type) {
    case types.SET_RECORDS:
      return action.payload;
    case types.ADD_INVOICE_TO_STORE:
      state.invoices.push(action.payload);
      return state;
    case types.ADD_COMMISSION_TO_STORE:
      state.commissions.push(action.payload);
      return state;
    case types.ADD_PAYMENT_TO_STORE:
      state.payments.push(action.payload);
      return state;
    case types.ADD_OR_MODIFY_EMPLOYEE_TO_STORE:
      let { updatedEmployeeData, updatedUserData } = action.payload;
      let employee = state.employees.find(
        (employee) => employee.id == updatedEmployeeData.id
      );
      if (employee != undefined) {
        return {
          ...state,
          employees: state.employees.map((employee) => {
            if (employee.id === updatedEmployeeData.id) {
              return updatedEmployeeData; // modify
            } else {
              return employee;
            }
          }),
          users: state.users.map((user) => {
            if (user.id === updatedUserData.id) {
              return updatedUserData; // modify
            } else {
              return user;
            }
          }),
        };
      } else {
        state.employees.push(updatedEmployeeData); // add
        state.users.push(updatedUserData);
        return state;
      }
    case types.ADD_OR_MODIFY_AGENCY_TO_STORE:
      let { updatedAgencyData } = action.payload;
      let updatedUser = action.payload.updatedUserData;
      let agency = state.agencies.find(
        (agency) => agency.id == updatedAgencyData.id
      );
      if (agency != undefined) {
        return {
          ...state,
          agencies: state.agencies.map((agency) => {
            if (agency.id === updatedAgencyData.id) {
              return updatedAgencyData; // modify
            } else {
              return agency;
            }
          }),
          users: state.users.map((user) => {
            if (user.id === updatedUser.id) {
              return updatedUser; // modify
            } else {
              return user;
            }
          }),
        };
      } else {
        state.agencies.push(updatedAgencyData); // add
        state.users.push(updatedUser);
        return state;
      }

    case types.ADD_EMPLOYER_TO_STORE:
      state.employers.push(action.payload);
      return state;
    case types.MODIFY_EMPLOYER_TO_STORE:
      return {
        ...state,
        employers: state.employers.map((employer) => {
          if (employer.id === action.payload.id) {
            return action.payload; // modify
          } else {
            return employer;
          }
        }),
      };
    case types.SET_AUTO_INVOICING:
      return { ...state, auto_invoicing: action.payload };
    default:
      return state;
  }
};

export const invoiceAddedReducer = (state = 0, action) => {
  switch (action.type) {
    case types.NEW_INVOICE_ADDED:
      return state + 1;
    default:
      return state;
  }
};

export const commissionAddedReducer = (state = 0, action) => {
  switch (action.type) {
    case types.NEW_COMMISSION_ADDED:
      return state + 1;
    default:
      return state;
  }
};

export const employeeAddedOrUpdatedReducer = (state = 0, action) => {
  switch (action.type) {
    case types.NEW_EMPLOYEE_ADDED_OR_UPDATED:
      return state + 1;
    default:
      return state;
  }
};

export const agencyAddedOrUpdatedReducer = (state = 0, action) => {
  switch (action.type) {
    case types.NEW_AGENCY_ADDED_OR_UPDATED:
      return state + 1;
    default:
      return state;
  }
};

export const employerAddedReducer = (state = 0, action) => {
  switch (action.type) {
    case types.NEW_EMPLOYER_ADDED:
      return state + 1;
    default:
      return state;
  }
};

export const employerUpdatedReducer = (state = 0, action) => {
  switch (action.type) {
    case types.EMPLOYER_UPDATED:
      return state + 1;
    default:
      return state;
  }
};

export const setBanksListReducer = (state = [], action) => {
  switch (action.type) {
    case types.SET_BANKS_LIST:
      return action.payload;
    default:
      return state;
  }
};
