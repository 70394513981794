import axios from "axios";

const getServer = () => {
  if (process.env.NODE_ENV === "production") {
    return `${process.env.REACT_APP_SERVER_API_URL_PROD}`;
  } else if (process.env.NODE_ENV === "development") {
    return `${process.env.REACT_APP_SERVER_API_URL_DEV}`;
  } else if (process.env.NODE_ENV === "staging") {
    return `${process.env.REACT_APP_SERVER_API_URL_DEV}`;
  } else {
    return "";
  }
};

class ApiCalls {
  SERVER = getServer();
  BASE_URL = `${this.SERVER}/api/v1`;

  login(data) {
    return axios({
      method: "post",
      url: `${this.BASE_URL}/user/login`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
      },
      data: data,
    });
  }

  getAllData(authToken) {
    return axios({
      method: "get",
      url: `${this.BASE_URL}/user/get_all_data`,
      headers: {
        "Content-type": "application/json",
        Authorization: "Token " + authToken,
      },
    });
  }

  createAgency(data) {
    return axios({
      method: "post",
      url: `${this.BASE_URL}/user/create_agency`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
      },
      data: data,
    });
  }

  createEmployee(data) {
    return axios({
      method: "post",
      url: `${this.BASE_URL}/user/create_employee`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
      },
      data: data,
    });
  }

  verifyOTP(groupId, otp, authToken) {
    return axios({
      method: "get",
      url: `${this.BASE_URL}/user/verify_otp/${groupId}/${otp}`,
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    });
  }

  createInvoice(data, authToken) {
    return axios({
      method: "post",
      url: `${this.BASE_URL}/payment/create_invoice`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      data: data,
    });
  }

  createCommission(data, authToken) {
    return axios({
      method: "post",
      url: `${this.BASE_URL}/payment/create_commission`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      data: data,
    });
  }

  updateEmployee(data, authToken) {
    return axios({
      method: "put",
      url: `${this.BASE_URL}/user/update_employee`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      data: data,
    });
  }

  getAllBanks(country) {
    return axios({
      method: "get",
      url: `${this.BASE_URL}/payment/get_all_banks/${country}`,
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  createEmployer(data, authToken) {
    return axios({
      method: "post",
      url: `${this.BASE_URL}/payment/create_employer`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      data: data,
    });
  }

  createSuperSite(data, authToken) {
    return axios({
      method: "post",
      url: `${this.BASE_URL}/user/create_supersite`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      data: data,
    });
  }

  startAutoInvoicing(authToken) {
    return axios({
      method: "put",
      url: `${this.BASE_URL}/payment/auto_invoicing/start`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    });
  }

  getCurrentUser(authToken) {
    return axios({
      method: "get",
      url: `${this.BASE_URL}/user/get_current_user`,
      headers: {
        "Content-type": "application/json",
        Authorization: "Token " + authToken,
      },
    });
  }

  logoutUser(authToken) {
    return axios({
      method: "get",
      url: `${this.BASE_URL}/user/logout`,
      headers: {
        "Content-type": "application/json",
        Authorization: "Token " + authToken,
      },
    });
  }

  updateAgency(data, authToken) {
    return axios({
      method: "put",
      url: `${this.BASE_URL}/user/update_agency`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      data: data,
    });
  }

  updateEmployer(data, id, authToken) {
    return axios({
      method: "put",
      url: `${this.BASE_URL}/payment/update_employer/${id}`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      data: data,
    });
  }

  sendResetCode(data) {
    return axios({
      method: "post",
      url: `${this.BASE_URL}/user/send_reset_code`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
      },
      data: data,
    });
  }

  verifyResetCode(username, code) {
    return axios({
      method: "get",
      url: `${this.BASE_URL}/user/verify_reset_code/${username}/${code}`,
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  changePassword(data, authToken) {
    return axios({
      method: "put",
      url: `${this.BASE_URL}/user/change_password`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      data: data,
    });
  }

  getBankBranches(bankId) {
    return axios({
      method: "get",
      url: `${this.BASE_URL}/payment/get_bank_branches/${bankId}`,
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  retryFailedTransfer(flutterwave_transaction_id, authToken) {
    return axios({
      method: "post",
      url: `${this.BASE_URL}/payment/retry_failed_transfer/${flutterwave_transaction_id}`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    });
  }
  reSendInvoice(invoiceId, authToken) {
    return axios({
      method: "post",
      url: `${this.BASE_URL}/payment/resend_invoice/${invoiceId}`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
    });
  }

  uploadProfilePicture(data, authToken) {
    return axios({
      method: "post",
      url: `${this.BASE_URL}/user/upload_profile_picture`,
      timeout: 120000,
      timeoutErrorMessage: "An Error occurred. Trasanction has taken too long",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${authToken}`,
      },
      data: data,
    });
  }
}

export default ApiCalls;
