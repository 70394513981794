import logo from "./logo.svg";
import "./App.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import WrongPath from "./utils/wrongUrlPath";
import Home from "./components/home";
import Menu from "./components/menu";
import SignUp from "./components/signup";
import { PrivateRoute } from "./utils/routing/privateRoute";
import { HomePageRoute } from "./utils/routing/homePageRoute";
import { LogoutRoute } from "./utils/routing/logoutRoute";
import PaymentResults from "./utils/payment_results";
import ForgotPassword from "./components/forgot_password";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#3B82F6",
      },
    },
  });
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          <HomePageRoute exact path="/" component={Home} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/payment-results" component={PaymentResults} />
          <Route exact path="/reset-password" component={ForgotPassword} />
          <LogoutRoute exact path="/signout" component={Home} />
          {/* protected route // to access this route user must be logged in */}
          <PrivateRoute path="/dashboard" component={Menu} />
          <Route component={WrongPath} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
