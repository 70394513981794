import types from "../actions/types/types";

export const createOrUpdateEmployeeReducer = (
  state = {
    employeeId: null,
  },
  action
) => {
  switch (action.type) {
    case types.CREATE_OR_UPDATE_EMPLOYEE:
      return action.payload;
    default:
      return state;
  }
};
