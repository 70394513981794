import React, { useState, useEffect } from "react";
import { Image, Modal } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  addOrModifyAgencyToStore,
  agencyAddedOrUpdated,
  showPopup,
} from "../../actions";
import ApiCalls from "../../api/apiCalls";
import { toast } from "react-toastify";
import avatar from "../../images/avatar.jpg";
import logo from "../../images/cheque-handler.jpg";
import TextField from "@material-ui/core/TextField";

const EditUsers = () => {
  const dispatch = useDispatch();
  const showEditUserPopup = useSelector(
    ({ showEditUserPopup }) => showEditUserPopup
  );
  const authToken = useSelector(({ userData: { token } }) => token);
  let api = new ApiCalls();
  const user = useSelector((state) => state.userData.user_data);
  const user_group_data = useSelector(
    (state) => state.userData.user_data.user_group_data
  );
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageStatus, setImageStatus] = useState("Add Image");

  const handleChangeName = (event) => {
    setUserName(event.target.value);
  };

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleClose = () => {
    dispatch(showPopup("edit_user", false));
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);

    let file = event.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
    if (file && ALLOWED_TYPES.includes(file.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(`${reader.result}`);
      };
      reader.readAsDataURL(file);
    } else {
      console.log("something Went Wrong!");
    }
  };

  useEffect(() => {
    setProfilePicture(
      user_group_data.profilePicture != null
        ? `${api.SERVER}${user_group_data.profilePicture}`
        : ""
    );
    setImageStatus(
      profilePicture != avatar ? "Change Image" : `${imageStatus}`
    );
    setUserName(user.username);
    setPhoneNumber(user_group_data.phoneNumber);
    setEmail(user.email);
  }, [user]);

  const saveUser = (event) => {
    event.preventDefault();
    event.stopPropagation();

    return;

    let formData = new FormData();
    let id = user_group_data.id; // this could also be "user_data.id"
    formData.append("username", userName);
    formData.append("phoneNumber", phoneNumber);
    formData.append("email", email);
    if (selectedFile != null) {
      formData.append("profilePicture", selectedFile);
      // console.log(formData.get("profilePicture").name);
    }

    // toast.promise(api.uploadProfilePicture(formData, authToken), {
    //   pending: "Please wait...",
    //   success: {
    //     render({ data }) {
    //       if (data.status === 200) {
    //         let responseBody = data.data;
    //         console.log(responseBody.message);
    //         return `${responseBody.message}`;
    //       }
    //     }
    //   },
    //   error: {
    //     render({ data }) {
    //       if (data.response) {
    //         console.log("status code " + data.response.status);
    //         let { message } = data.response.data;
    //         console.log("errorMessage:", message);
    //         return `${message}`;
    //       } else {
    //         console.log("An error occurred, try again later");
    //         return "An error occurred, try again later";
    //       }
    //     }
    //   }
    // });

    // UpdateUser endpoint is not in APICall yet to be created.
    toast.promise(api.updateUser(formData, id, authToken), {
      pending: "Please wait...",
      success: {
        render({ data }) {
          if (data.status === 200) {
            let responseBody = data.data;
            console.log(responseBody.message);
            return `${responseBody.message}`;
          }
        },
      },
      error: {
        render({ data }) {
          if (data.response) {
            console.log("status code " + data.response.status);
            let { message } = data.response.data;
            console.log("errorMessage:", message);
            return `${message}`;
          } else {
            console.log("An error occurred, try again later");
            return "An error occurred, try again later";
          }
        },
      },
    });
  };

  return (
    <Modal
      show={showEditUserPopup}
      onHide={handleClose}
      centered
      backdrop={false}
      size="xl"
    >
      <form onSubmit={saveUser}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#eee",
            fontSize: 40,
            position: "sticky",
            top: 0,
            zIndex: 2,
          }}
        >
          <button
            className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center text-sm focus:outline-none transition hover:shadow-md shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
            type="submit"
          >
            <span>
              {/* {editType === "update" ? "Update Agency" : "Save Agency"} */}
              Update User
            </span>
          </button>
        </Modal.Header>
        <div className="agency-details-container">
          <div className="mr-4 ml-4 lg:mr-20 lg:ml-20 xl:mr-30 lx:ml-30">
            <div className="relative flex flex-col min-w-0 break-words w-full rounded-xl bg-blueGray-200 border-0 pt-20">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                {/* <form onSubmit={saveAgency}> */}
                <h6 className="text-blueGray-500 text-sm mt-3 mb-6 font-bold uppercase">
                  User Details
                </h6>

                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        User Name
                      </label>
                      <input
                        type="text"
                        disabled
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangeName}
                        value={userName}
                      />
                    </div>
                  </div>

                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        {"Phone Number (add country code)"}
                      </label>
                      <input
                        type="tel"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangePhoneNumber}
                        value={phoneNumber}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        required
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-700 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangeEmail}
                        value={email}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex items-center flex items-center justify-center mt-4">
                  <button
                    className="px-6 py-2 ml-4 font-semibold cursor-pointer text-center focus:outline-none transition hover:shadow-lg shadow hover:bg-indigo-900 rounded-full text-white bg-indigo-600"
                    type="submit"
                  >
                    <span>
                      {/* {editType === "update"
                        ? "Update Agency"
                        : "Create Agency"} */}
                      Update User
                    </span>
                  </button>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
          <div className="agency-profile-picture-container mt-5">
            <div className="agency-profile-picture">
              <input
                type="file"
                onChange={handleFileChange}
                name="profileImage"
                id="photoFile"
                className="d-none"
              />
              <label
                className="profile-button w-100 h-100"
                role="button"
                htmlFor="photoFile"
              >
                <Image
                  src={profilePicture != "" ? profilePicture : avatar}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "50%",
                  }}
                  alt="Loading..."
                />
                <div className="profile-image">
                  {" "}
                  <span> {imageStatus} </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditUsers;
