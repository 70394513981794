import types from "../actions/types/types";

export const verifyOTPPopupReducer = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_POPUP.VERIFY_OTP:
      return action.payload;
    default:
      return state;
  }
};

export const createInvoicePopupReducer = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_POPUP.CREATE_INVOICE:
      return action.payload;
    default:
      return state;
  }
};

export const createCommissionPopupReducer = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_POPUP.CREATE_COMMISSION:
      return action.payload;
    default:
      return state;
  }
};

export const selectEmployeePopupReducer = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_POPUP.SELECT_EMPLOYEE:
      return action.payload;
    default:
      return state;
  }
};

export const selectAgencyPopupReducer = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_POPUP.SELECT_AGENCY:
      return action.payload;
    default:
      return state;
  }
};

export const editEmployeePopupReducer = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_POPUP.EDIT_EMPLOYEE:
      return action.payload;
    default:
      return state;
  }
};

export const editAgencyPopupReducer = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_POPUP.EDIT_AGENCY:
      return action.payload;
    default:
      return state;
  }
};

export const editEmployerPopupReducer = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_POPUP.EDIT_EMPLOYER:
      return action.payload;
    default:
      return state;
  }
};

export const showInvoicePopupReducer = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_POPUP.SHOW_INVOICE:
      return action.payload;
    default:
      return state;
  }
};

export const editUserPopupReducer = (state = false, action) => {
  switch (action.type) {
    case types.SHOW_POPUP.EDIT_USER:
      return action.payload;
    default:
      return state;
  }
};