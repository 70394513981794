import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";

// components

const Status = ({ status }) => {
  let badge =
    status === "sent"
      ? "blue"
      : status === "paid"
      ? "purple"
      : status === "transfering"
      ? "yellow"
      : status === "transfer failed" || status === "payment failed"
      ? "red"
      : status === "withdrawn"
      ? "green"
      : "blue";
  return (
    <div
      style={{
        width: 12,
        height: 12,
        borderRadius: "50%",
        marginLeft: 4,
      }}
      className={`bg-${badge}-500 text-${badge}-500`}
      title={status}
    ></div>
  );
};

function CardInvoices({ history }) {
  const [invoicesData, setInvoicesData] = useState([]);
  const employers = useSelector(({ records: { employers } }) => employers);

  const invoices = useSelector(({ records: { invoices } }) => invoices);
  const payments = useSelector(({ records: { payments } }) => payments);
  const invoices_currency = useSelector(
    ({ records: { invoices_currency } }) => invoices_currency
  );
  useEffect(() => {
    let invoicesRecords;
    if (invoices.length > 5) {
      invoicesRecords = invoices.slice(0, 5); // pick first five elements only
    } else {
      invoicesRecords = invoices;
    }

    const data = invoicesRecords.map((invoice) => {
      let payment = payments.find((payment) => payment.id === invoice.payment);
      let employer = employers.find(
        (employer) => employer.id === invoice.employer
      );
      return {
        amount: `${
          invoices_currency == "USD" ? "$" : invoices_currency
        } ${Number.parseInt(payment.amount).toLocaleString()}`,
        transactionFee:
          payment.transactionFee != null
            ? Number.parseInt(payment.transactionFee).toLocaleString()
            : " ",
        status: <Status status={invoice.status} />,
        employer: employer != undefined ? employer.name : "",
      };
    });
    setInvoicesData(data);
  }, [invoices]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-md rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                List of Invoices
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={(event) => {
                  history.push("/dashboard/invoices");
                }}
              >
                See all
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse ">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Employer
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Amount
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {invoicesData.map((invoice) => (
                <tr>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {invoice.employer}
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {invoice.amount}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {invoice.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default withRouter(CardInvoices);
