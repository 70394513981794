import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/cheque_handler_square.png";

const NavItem = ({ title, link, children, active }) => (
  <li
    className={"nav-item" + (active ? " active" : "")}
    style={{ fontSize: 15 }}
  >
    <Link to={link} className="nav-link" title={title}>
      {children}
      <span className="nav-text">{title}</span>
    </Link>
  </li>
);

const MobileNavItem = ({ to, active, children }) => (
  <Link
    className={"flex-fill text-center nav-link" + (active ? " active" : "")}
    to={to}
  >
    {children}
  </Link>
);

/**
 * menu of the app
 */
const MenuBar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMenuExpanded, setMenuExpanded] = useState(true);
  const [active, setActive] = useState("");

  const user_group_data = useSelector(
    (state) => state.userData.user_data.user_group_data
  );

  const location = useLocation();

  const handleClick = (e) => {
    e.preventDefault();
    setMenuOpen((d) => !d);
  };

  const handleExpand = (e) => {
    e.preventDefault();
    setMenuExpanded((d) => !d);
  };

  /**
   * change the status of the side nav whenever toggled.
   */
  useEffect(() => {
    if (!isMenuExpanded) {
      document.body.classList.add("sidenav-toggled");
    } else document.body.classList.remove("sidenav-toggled");
  }, [isMenuExpanded]);

  /**
   * close the nav whenver a navigation takes place.
   */
  useEffect(() => {
    setActive(location.pathname.split("/")[2] || "");
    setMenuOpen(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  /**
   * addition class when toggled.
   */
  const additionalClass = isMenuOpen ? " toggled" : "";

  return (
    <div>
      <nav className="nav nav-pills flex-row fixed-bottom menubar">
        <MobileNavItem
          active={["dashboard", ""].indexOf(active) !== -1}
          to="/dashboard"
        >
          <i className="fas fa-home fa-fw"></i>
        </MobileNavItem>
        <MobileNavItem active={active === "profile"} to="/dashboard/profile">
          <i className="fas fa-user fa-fw"></i>
        </MobileNavItem>

        {user_group_data.type === "supersite" ? (
          <>
            <MobileNavItem
              active={active === "invoices"}
              to="/dashboard/invoices"
            >
              <i className="fas fa-hand-holding-usd fa-fw"></i>
            </MobileNavItem>
            <MobileNavItem
              active={active === "commissions"}
              to="/dashboard/commissions"
            >
              <i className="fa fa-credit-card fa-fw"></i>
            </MobileNavItem>
            <MobileNavItem
              active={active === "employees"}
              to="/dashboard/employees"
            >
              <i className="fa fa-users fa-fw"></i>
            </MobileNavItem>
          </>
        ) : user_group_data.type === "agency" ? (
          <>
            <MobileNavItem
              active={active === "invoices"}
              to="/dashboard/invoices"
            >
              <i className="fas fa-hand-holding-usd fa-fw"></i>
            </MobileNavItem>
            <MobileNavItem
              active={active === "employees"}
              to="/dashboard/employees"
            >
              <i className="fa fa-users fa-fw"></i>
            </MobileNavItem>
            <MobileNavItem
              active={active === "contact-us"}
              to="/dashboard/contact-us"
            >
              <i className="fas fa-comments fa-fw"></i>
            </MobileNavItem>
          </>
        ) : user_group_data.type === "employee" ? (
          <>
            <MobileNavItem
              active={active === "retainers"}
              to="/dashboard/retainers"
            >
              <i className="fas fa-hand-holding-usd fa-fw"></i>
            </MobileNavItem>
            <MobileNavItem
              active={active === "contact-us"}
              to="/dashboard/contact-us"
            >
              <i className="fas fa-comments fa-fw"></i>
            </MobileNavItem>
          </>
        ) : (
          <></>
        )}

        <a
          className="flex-fill text-center nav-link"
          href="#."
          id="toggleMenu"
          onClick={handleClick}
        >
          <i className="fas fa-bars fa-fw"></i>
        </a>
      </nav>

      <nav className={`vertical-nav ${additionalClass}`} id="sidebar">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="d-flex profile text-light  text-black"
          // style={{ backgroundColor: "rgba(0,0,0,0.2)" }}
        >
          <img
            src={logo}
            alt="..."
            style={{ width: 120 }}
            // className=" rounded-circle shadow-sm"
          />
        </div>

        <ul className="nav link-container d-flex flex-column mb-0">
          <NavItem
            link="/dashboard"
            title="Overview"
            active={["dashboard", ""].indexOf(active) !== -1}
          >
            <i className="fas fa-home fa-fw" />
          </NavItem>

          <NavItem
            link="/dashboard/profile"
            title="Profile"
            active={active === "profile"}
          >
            <i class="fas fa-user fa-fw"></i>
          </NavItem>
          {user_group_data.type === "agency" ? (
            <>
              <NavItem
                link="/dashboard/invoices"
                title="Invoices"
                active={active === "invoices"}
              >
                <i className="fas fa-hand-holding-usd fa-fw"></i>
              </NavItem>
              <NavItem
                link="/dashboard/commissions"
                title="Commissions"
                active={active === "commissions"}
              >
                <i className="fa fa-credit-card fa-fw"></i>
              </NavItem>
              <NavItem
                link="/dashboard/employees"
                title="Employees"
                active={active === "employees"}
              >
                <i className="fa fa-users fa-fw"></i>
              </NavItem>
            </>
          ) : user_group_data.type === "supersite" ? (
            <>
              <NavItem
                link="/dashboard/invoices"
                title="Invoices"
                active={active === "invoices"}
              >
                <i className="fas fa-hand-holding-usd fa-fw"></i>
              </NavItem>
              <NavItem
                link="/dashboard/commissions"
                title="Commissions"
                active={active === "commissions"}
              >
                <i className="fa fa-credit-card fa-fw"></i>
              </NavItem>
              <NavItem
                link="/dashboard/employees"
                title="Employees"
                active={active === "employees"}
              >
                <i className="fa fa-users fa-fw"></i>
              </NavItem>
              <NavItem
                link="/dashboard/agencies"
                title="Agencies"
                active={active === "agencies"}
              >
                <i class="fa fa-building" aria-hidden="true"></i>
              </NavItem>
              <NavItem
                link="/dashboard/employers"
                title="Employers"
                active={active === "employers"}
              >
                <i class="fa fa-briefcase" aria-hidden="true"></i>
              </NavItem>

              {/* <div className="my-md-2"></div> */}

              <NavItem
                link="/dashboard/settings"
                title="Settings"
                active={active === "settings"}
              >
                <i className="fa fa-cog fa-fw" aria-hidden="true"></i>
              </NavItem>
            </>
          ) : user_group_data.type === "employee" ? (
            <>
              <NavItem
                link="/dashboard/retainers"
                title="Retainers"
                active={active === "retainers"}
              >
                <i className="fas fa-hand-holding-usd fa-fw"></i>
              </NavItem>
            </>
          ) : (
            <></>
          )}
          <div className="my-md-2"></div>
          {user_group_data.type === "agency" ||
          user_group_data.type === "employee" ? (
            <>
              <NavItem
                link="/dashboard/contact-us"
                title="Contact Us"
                active={active === "contact-us"}
              >
                <i class="fas fa-comments fa-fw"></i>
              </NavItem>
            </>
          ) : (
            <></>
          )}
          {/* <div className="my-md-2"></div> */}
          <li className="nav-item">
            <a href="/signout" className="nav-link" title="Log Out">
              <i className="fas fa-sign-out-alt fa-fw"></i>
              <span className="nav-text">Log Out</span>
            </a>
          </li>
        </ul>

        <div className="nav-toggler">
          <a
            href="#."
            className="nav-link w-100 text-center px-0"
            id="sidenavToggler"
            onClick={handleExpand}
          >
            <i
              className="far fa-arrow-alt-circle-left fa-fw fa-2x"
              style={{ color: "#696969" }}
            ></i>
          </a>
        </div>
      </nav>
    </div>
  );
};

export default MenuBar;
